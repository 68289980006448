import React, {useState, useEffect, useRef} from "react";
import { Card, Container, Row, Col, Button, Form } from 'react-bootstrap';
import firebase from 'firebase/compat/app';
import { collection, addDoc, query, where, getDocs, getFirestore, count } from 'firebase/firestore';
import { firestore, storage } from '../firebase';
import 'firebase/compat/firestore';
// import axios from 'axios';
import OpenAI from "openai";
// import Countdown from 'react-countdown';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import { doc, updateDoc, arrayUnion, setDoc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import mixpanel from 'mixpanel-browser';
// import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
// import Accordion from 'react-bootstrap/Accordion';
// import UploadPhoto from './UploadPhoto';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
// import { storage } from './firebase';

const auth = getAuth();

let subIndexG

const EnergyPools = () => {
  const [population, setPopulation] = useState(0);
  const [subPopulation, setSubPopulation] = useState(0);
  const [triggerPopulation, setTriggerPopulation] = useState(false);
  const [popExplanation, setPopExplanation] = useState('');
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const db = firebase.firestore();

  const getPopulation = async (text) =>{

    const user = auth.currentUser;
    // const userid = user.uid
    // const photo = user.photoURL

    // if (!user) {
    //   setShowSignInButton(true); // Show the sign-in modal if user is not signed in
    //   return;
    // }

    // if(subIndexG == null){

    // }

    
    try {
      
      const user = auth.currentUser;
      const userid = user.uid
      // console.log('userid: ', userid)
      // Get reference to the user's document in the 'users' collection
      const userDocRef = doc(db, 'users', userid);
  
      // Get the document snapshot
      const userDocSnap = await getDoc(userDocRef);
  
      // Check if the document exists
      if (userDocSnap.exists()) {
        // Get the 'subliminals' field data
        const subliminalsData = userDocSnap.data().subliminals;
        // console.log(subliminalsData)
  
        // Check if 'tags' field exists in the 'subliminals' object
        if (subliminalsData && subliminalsData[subIndexG].hasOwnProperty('population')) {
          // console.log('Population field exists:', subliminalsData[subIndexG].population);
          // console.log('population:', subliminalsData[subIndexG].mp3length);

          // return true; // Field exists
          //**** */undo
          // convertTextToAudio(searchText, subfreq);
          setPopulation(subliminalsData[subIndexG].population.figure)
          setPopExplanation(subliminalsData[subIndexG].population.explanations)
          setTriggerPopulation(true)
          setIsLoading(false);

        } else {
          // console.log('Population field does not exist.');
          // return false; // Field does not exist
          console.log('e wan create')
          // get tag
          const openai = new OpenAI({apiKey:'sk-proj-5Xc3ZBosQlmDs8LndaxtT3BlbkFJiDpsq70OVKc3A7rkkEYr', dangerouslyAllowBrowser: true});

          try {
            // Prepare the prompt for OpenAI Completions API
            const prompt = `
              Considering that the total estimated number of people that has lived dead and alive is 117 billion. Using current statistics percentages, 
              give a low estimate of the number of people dead or alive that have acheived the input text:
              Rule: Do not give the number of people that has expressed the desire or sort to acheive the input text as this isnt the goal. 
              If input text is about anything in the human body, give the number of people that already naturally possess the look or desire and are not looking for remedy because they were born with it 
              i.e don't look for statistics of people that used the remedy.  For example: If the input text is 'I want to grow taller', find statistics of people who are taller not people who have the desire to be taller.  
              If it has nothing to do with human body, give the number of people that has actually done it. Also, find the main need of the user in the input text, do not add to the analysis the method they want to use in acheive the desire e.g manifestation, affirmation, visualisation etc. 
              Only analyse main desire or result the user is looking for.
              Input text: "${text}"
              Respond with only a figure, 1 short sentence about what the figure represents and another sentence about how you came about the figure. Respond with numbers like 3000000000 instead of words like 3 billion.
              result format: let the response come in a dictionary e.g {figure:"10000000", explanations: "This figure represents the estimated number..."}
            `;

            // Call OpenAI API to get relevant tags
            const response = await openai.chat.completions.create({
              // 'https://api.openai.com/v1/completions',
              messages:[{
                "role": "user",
                "content": prompt,
            }],
              model:"gpt-4o-mini",
            });

            console.log('population:', response.choices[0].message.content)

            // Convert the string to a JavaScript object
            const parsedResponse = JSON.parse(
              response.choices[0].message.content
                .replace(/figure:/, '"figure":') // Add double quotes around keys
                .replace(/explanations:/, '"explanations":') // Add double quotes around keys
            );

            // Parse the response to extract the tags
            const populationFromAPI = parsedResponse.figure
              // .trim()
              // .split(',')
              // .map(tag => tag.trim());

            // Set the selected tags in the state
            setPopulation(populationFromAPI);
            setPopExplanation(parsedResponse.explanations)
            // console.log('tagsFromAPI: ', tagsFromAPI)

            // Update the subliminals array with the new tags
            const updatedSubliminals = [...subliminalsData];
            updatedSubliminals[subIndexG] = {
              ...updatedSubliminals[subIndexG],
              population: parsedResponse
            };

            // Save the updated subliminals array back to Firestore
            await updateDoc(userDocRef, {
              subliminals: updatedSubliminals
            });

            // Once tags are selected, call the convertTextToAudio function
            // convertTextToAudio(searchText, subfreq);
            setSubPopulation(populationFromAPI)
            setTriggerPopulation(true)
            setIsLoading(false);

          } catch (error) {
            console.error('Error fetching tags from OpenAI:', error);
            setIsLoading(false);

          } finally {
            setIsLoading(false);
            setTriggerPopulation(true)
          }

        }
      } else {
        console.log('Document does not exist.');
        setIsLoading(false);
        return false; // Document does not exist
      }
    } catch (error) {
      console.error('Error fetching document:', error);
      setIsLoading(false);
      return false; // Handle error
    }

  }


  function numberToWords(num) {
    const g = [
        '', 'Thousand', 'Million', 'Billion', 'Trillion', 'Quadrillion', 'Quintillion'
    ];

    // Splits the number into groups of three digits starting from the right
    function chunkNumber(numStr) {
        let chunks = [];
        while (numStr.length > 0) {
            chunks.push(numStr.slice(-3));
            numStr = numStr.slice(0, -3);
        }
        return chunks;
    }

    // Handles numbers like 1,000,000 or 1,000,000,000, etc.
    function specialCases(num) {
        const numStr = num.toString();
        if (numStr.match(/^1(0)+$/)) {  // Matches numbers like 1000, 1000000, 1000000000, etc.
            const power = numStr.length - 1;
            const suffixIndex = Math.floor(power / 3);  // Get the index for thousand, million, etc.
            return `1 ${g[suffixIndex]}`;
        }
        return null;
    }

    // Convert numbers normally using digits for the number part and capitalize the suffix
    function convertWithDigit(num) {
        let numStr = num.toString();
        let chunks = chunkNumber(numStr);

        let wordArray = chunks.map((chunk, index) => {
            let chunkInt = parseInt(chunk);

            // Skip empty groups (i.e., for numbers like 1,000,000,000 where some groups are 000)
            if (chunkInt === 0) return '';

            // Return digit + capitalized suffix (e.g., 2 Million)
            return `${chunkInt} ${g[index]}`;
        });

        return wordArray
            .filter(Boolean) // Remove empty parts
            .reverse() // Correct the order of chunks
            .join(' ') // Join the result with spaces
            .trim();
    }

    return specialCases(num) || convertWithDigit(num); // Use special case if applicable
}


  return (
    <div 
    style={{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between', // Pushes content apart vertically
    alignItems: 'center', // Centers content horizontally
    minHeight: '100vh', // Makes sure it takes up full viewport height
    padding: '20px', // Adds padding around the content
    // backgroundColor: '#f5f5f5', // Background color (can be changed)
    boxSizing: 'border-box', // Ensures padding doesn't affect width
}}>
      <h1>EnergyPools</h1>
      <p>This is the EnergyPools app. More content coming soon!</p>

      <div style={{
            background: 'linear-gradient(to right, green, grey)', 
            borderRadius: 15, 
            borderWidth: 0,
            borderStyle: 'solid',
            padding: 5, 
            width: '80%', 
            margin: 5,
            height: 100, 
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
      <Form.Control 
                as="textarea"
                rows={1}
                style={{ 
                    color: "#7A7A7A", 
                    fontFamily: "Risque", 
                    fontStyle: "thin", 
                    fontSize: 50,
                    fontWeight: 100, 
                    margin: 0, 
                    borderRadius: 15, 
                    border: 'none', 
                    width: '100%',
                    height: '100%',
                    padding: 10,
                    boxSizing: 'border-box',
                    textTransform: "capitalize",
                    overflow: 'hidden',   // Prevent scrollbar
                    resize: 'none'        // Prevent manual resizing
                }}
                type="text" 
                value={'personName'}
                onChange={'(e) => setPersonName(e.target.value)'} 
                placeholder="Jamie ?" 
            />
        </div>
      <div id="wheel"></div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center', // Vertically center the text
                      textAlign: 'center', // Horizontally center the text
                      marginBottom: 25,
                      fontSize: 14,
                      height: '100%', // Make sure the div has height to center vertically
                    }}
                  >
                    <div>
                      <label htmlFor="speedInput">
                        {subIndexG == null ? (
                          <a> Play and Save to begin channelling. </a>
                        ) : (
                          <>
                            Click the button below to channel the energy from the
                            <b> {numberToWords(population)} Lives, both past or present,</b>{' '}
                            <a>
                              {showFullText
                                ? popExplanation.substring(popExplanation.indexOf('who')) // Show full text
                                : `${popExplanation.substring(popExplanation.indexOf('who'), popExplanation.indexOf('who') + 25)}...`} {/* Show first 25 characters */}
                            </a>
                            <span
                              style={{ color: 'blue', cursor: 'pointer', marginLeft: '5px' }}
                              onClick={toggleText}
                            >
                              {showFullText ? 'read less' : 'read more'}
                            </span>
                          </>
                        )}
                      </label>
                      {/* Instruction player */}
                      <audio controls style={{ marginTop: 15 ,display: 'flex', alignItems: 'center', }}>
                        <source
                          src="https://firebasestorage.googleapis.com/v0/b/energyvoo.appspot.com/o/testimonies%2FElevenLabs_2024-09-10T16_21_44_Angelina_gen_s50_sb75_se0_b_m2.mp3?alt=media&token=dfece892-c18a-40fe-9e73-8b79966466d5"
                          type="audio/mpeg"
                        />
                        Your browser does not support the audio element.
                      </audio>

                      <div style={{ display: 'flex',  marginTop: 15 , justifyContent: 'center', alignItems: 'center', flexDirection: 'row', height: '10vh' }}>
                          <Button
                            variant="dark"
                            onClick={() => {}}
                            style={{ display: 'flex', alignItems: 'center', borderRadius: 0, marginBottom: '0px', }}
                          >
                            I Join This Linage
                          </Button>
                          
                        
                        </div>
                    </div>
                  </div>
    </div>
    
  );
};

export default EnergyPools;