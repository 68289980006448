import React, { useEffect, useRef, useState } from "react";

const EverythingisPerceived = () => {
  const canvasRef = useRef(null);
  const [visibilityMode, setVisibilityMode] = useState("both"); // State to control visibility

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    const centerX = canvas.width / 2; // Center of the circle and triangle
    const centerY = canvas.height / 2;
    const radius = 100; // Radius of the circle

    // Define the triangle vertices on the circle's perimeter
    const triangleVertices = [
      { x: centerX + radius * Math.cos(0), y: centerY + radius * Math.sin(0) },
      {
        x: centerX + radius * Math.cos((2 * Math.PI) / 3),
        y: centerY + radius * Math.sin((2 * Math.PI) / 3),
      },
      {
        x: centerX + radius * Math.cos((4 * Math.PI) / 3),
        y: centerY + radius * Math.sin((4 * Math.PI) / 3),
      },
    ];

    // Variables for movement and corner dot sizes
    let circleAngle = 0; // Angle for the circle dot
    let triangleProgress = 0; // Progress along the triangle's perimeter (0 to 1)
    const cornerDotSizes = [2, 2, 2]; // Initial size of black dots at triangle vertices

    const redDotSize = 5; // Size of the red dot

    // Track the current segment index for detecting corner passes
    let lastSegmentIndex = -1;

    const draw = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas

      // Conditionally draw the circle
      if (visibilityMode === "circle" || visibilityMode === "both") {
        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
        ctx.strokeStyle = "black";
        ctx.lineWidth = 2;
        ctx.stroke();
      }

      // Conditionally draw the triangle
      if (visibilityMode === "triangle" || visibilityMode === "both") {
        // Draw the triangle
        ctx.beginPath();
        ctx.moveTo(triangleVertices[0].x, triangleVertices[0].y);
        ctx.lineTo(triangleVertices[1].x, triangleVertices[1].y);
        ctx.lineTo(triangleVertices[2].x, triangleVertices[2].y);
        ctx.closePath();
        ctx.strokeStyle = "blue";
        ctx.lineWidth = 2;
        ctx.stroke();

        // Draw black dots at the triangle corners, only within the triangle
        triangleVertices.forEach((vertex, index) => {
          ctx.save(); // Save the current state

          // Create a clipping region for the triangle
          ctx.beginPath();
          ctx.moveTo(triangleVertices[0].x, triangleVertices[0].y);
          ctx.lineTo(triangleVertices[1].x, triangleVertices[1].y);
          ctx.lineTo(triangleVertices[2].x, triangleVertices[2].y);
          ctx.closePath();
          ctx.clip(); // Apply the clipping region

          // Draw the black dot, only the part inside the triangle will be visible
          ctx.beginPath();
          ctx.arc(vertex.x, vertex.y, cornerDotSizes[index], 0, Math.PI * 2);
          ctx.fillStyle = "black";
          ctx.fill();

          ctx.restore(); // Restore the canvas to remove the clipping region
        });

        // Calculate the position of the dot on the triangle
        let segmentIndex = Math.floor(triangleProgress * 3); // Current edge (0, 1, 2)
        let segmentStart = triangleVertices[segmentIndex];
        let segmentEnd =
          triangleVertices[(segmentIndex + 1) % triangleVertices.length];
        let segmentFraction = (triangleProgress * 3) % 1; // Progress within the segment

        const dotXTriangle =
          segmentStart.x + (segmentEnd.x - segmentStart.x) * segmentFraction;
        const dotYTriangle =
          segmentStart.y + (segmentEnd.y - segmentStart.y) * segmentFraction;

        // Draw the moving dot on the triangle
        ctx.beginPath();
        ctx.arc(dotXTriangle, dotYTriangle, redDotSize, 0, Math.PI * 2);
        ctx.fillStyle = "red";
        ctx.fill();

        // Check if the triangle dot has passed a corner
        if (segmentIndex !== lastSegmentIndex) {
          cornerDotSizes[segmentIndex] += redDotSize * 0.5; // Increase the size of the black dot
          lastSegmentIndex = segmentIndex; // Update the last segment index
        }
      }

      // Calculate the position of the dot on the circle
      if (visibilityMode === "circle" || visibilityMode === "both") {
        const dotXCircle = centerX + radius * Math.cos(circleAngle);
        const dotYCircle = centerY + radius * Math.sin(circleAngle);

        // Draw the moving dot on the circle
        ctx.beginPath();
        ctx.arc(dotXCircle, dotYCircle, redDotSize, 0, Math.PI * 2);
        ctx.fillStyle = "red";
        ctx.fill();
      }

      // Update the angles and progress
      circleAngle = (circleAngle + 0.02) % (2 * Math.PI); // Increment the angle for the circle dot
      triangleProgress = (triangleProgress + 0.005) % 1; // Increment progress for the triangle dot

      requestAnimationFrame(draw); // Call draw on the next animation frame
    };

    draw(); // Start the animation
  }, [visibilityMode]); // Redraw whenever visibilityMode changes

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Full viewport height
        backgroundColor: "#f0f8ff",
      }}
    >
      <select
        value={visibilityMode}
        onChange={(e) => setVisibilityMode(e.target.value)}
        style={{ marginBottom: "20px", padding: "10px", fontSize: "16px" }}
      >
        <option value="both">Both</option>
        <option value="circle">Circle</option>
        <option value="triangle">Triangle</option>
      </select>
      <canvas
        ref={canvasRef}
        width={400}
        height={400}
        style={{
          border: "1px solid black",
        }}
      />
    </div>
  );
};

export default EverythingisPerceived;
