import React from "react";
import "./styles.css";

const UserPosture = ({ posture, energy  }) => {
  const radius = 150; // Radius of the outermost circle

  const positions = {
    top: `calc(50% - 10px)`, // Center the head vertically on the edge
    // left: `calc(50% + ${radius}px - 10px)`, // Center the head horizontally on the edge
};

// Define gradients for different postures
const gradients = {
  standing: `linear-gradient(to top, green ${energy}%, red ${100 - energy}%)`, // Feet to head
  sitting: `linear-gradient(to right, green ${energy}%, red ${100 - energy}%)`, // Left to right
  laying: `linear-gradient(to right, green ${energy}%, red ${100 - energy}%)`, // Left to right
};

const bodyStyles = {
  standing: {
    width: "10px",
    height: "60px",
    background: gradients.standing, // Apply vertical gradient
    transform: "translate(-50%, 0)", // Align body with the axis
  },
  sitting: {
    width: "30px",
    height: "10px",
    background: gradients.sitting, // Apply horizontal gradient
    transform: "translate(-50%, 50%) rotate(-90deg)", // L-shape
  },
  laying: {
    width: "60px",
    height: "10px",
    background: gradients.laying, // Apply horizontal gradient
    transform: "translate(-50%, 50%)", // Horizontal alignment
  },
};

  return (
    <div className="user">
      {/* Circle Head */}
      {/* <div
        className="head"
        style={{
          position: "absolute",
          width: "20px",
          height: "20px",
          borderRadius: "50%",
          backgroundColor: "black",
          ...positions,
        }}
      ></div> */}
      {/* Body */}
      {/* Circular motion wrapper */}
      {/* <div
        className="rotating-body"
        style={{
          position: "absolute",
          top: `calc(50% - ${radius}px)`, // Center vertically on outermost circle
          left: "50%", // Center horizontally
          transformOrigin: "0px 150px", // Set rotation origin at the Earth's center
          animation: "rotate 4s linear infinite", // Circular motion
        }}
      > */}
      <div
        className="rotating-body"
        style={{
          position: "absolute",
          top: `calc(50% - ${radius}px)`, // Center vertically on outermost circle
          left: "50%", // Center horizontally
          transformOrigin: "0px 150px", // Set rotation origin at the Earth's center
          animation: "rotate 8s linear infinite", // Circular motion
        }}
      >
      <div
        className="body"
        style={{
          position: "absolute",
          ...bodyStyles[posture],
          ...positions,
        //   top: `calc(50% + ${radius}px)`, // Align body with the head on the circle edge
        //   left: `calc(50% + ${radius}px)`, // Center body with head
        }}
      ></div>

      </div>
      
    </div>
  );
};

export default UserPosture;
