import React, { useState } from 'react';
import { motion } from 'framer-motion';
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const EnergyofEverything = () => {
  const [step, setStep] = useState(0); // 0: Initial, 1: Turn Red to Blue, 2: Merge Ropes, 3: Change Colors

  // Animation variants for tying the knot
  const tieVariants = {
    untied: { pathLength: 0 },
    tied: {
      pathLength: 1,
      transition: {
        duration: 3,
        ease: 'easeInOut',
      },
    },
  };

  // Animation variants for untying the knot
  const untieVariants = {
    tied: { pathLength: 1 },
    untied: {
      pathLength: 0,
      transition: {
        duration: 3,
        ease: 'easeInOut',
      },
    },
  };

  // State to track whether the knot is tied or untied
  const [isTied, setIsTied] = useState(false);

  // State to track the selected date
  const [selectedDate, setSelectedDate] = useState(null);

  // State to manage the current view: 'calendar' or 'knot'
  const [currentView, setCurrentView] = useState('calendar');

  // Generate controlled control points for the paths to make the ropes longer and closer
  const generateControlledPath = (offset = 0) => {
    const base = 200; // Increase the base Y-coordinate for larger SVG paths
    const variation = 50; // Increase the variation for larger rope path height
    const randomControlPoint = () => base + Math.random() * variation + offset;
    return `
      M10,${randomControlPoint()} 
      C${randomControlPoint()},${randomControlPoint()} ${randomControlPoint()},${randomControlPoint()} 390,${randomControlPoint()}
    `;
  };

  // State to store the controlled paths for each rope
  const [blueRopePath, setBlueRopePath] = useState(generateControlledPath());
  const [redRopePath, setRedRopePath] = useState(generateControlledPath(20)); // Slight offset for variation
  const [greenRopePath, setGreenRopePath] = useState(generateControlledPath(40)); // Additional offset for green

  // Function to toggle the knot state and generate new paths when tying
  const toggleTie = () => {
    setIsTied(!isTied);
    if (!isTied) {
      // Generate new controlled paths for the knot when tying
      setBlueRopePath(generateControlledPath());
      setRedRopePath(generateControlledPath());
      setGreenRopePath(generateControlledPath());
    }
  };

  // Function to handle the Finish button click after selecting a date
  const handleFinish = () => {
    if (selectedDate) {
      toggleTie();
      setCurrentView('knot');
    } else {
      alert('Please select a date before proceeding.');
    }
  };

  return (
    <div className="container text-center mt-5">
  
      {/* Conditional Rendering Based on Current View */}
      {currentView === 'calendar' ? (
        <div className="my-5">
        <p>Think of the date you learned how to manufacture the recurrent feeling you have.</p>
          <h2>Select a Date</h2>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            inline
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
          <div>
            <button
              style={{
                fontFamily: 'Arial, sans-serif',
                fontSize: '18px',
                padding: '15px 30px',
                marginTop: 'auto',
                marginBottom: '90px',
                borderRadius: '8px',
                width: '90%',
              }}
              className="btn btn-primary mt-5"
              onClick={handleFinish}
              disabled={!selectedDate}
              aria-label="Finish Selecting Date"
            >
              Start
            </button>
          </div>
        </div>
      ) : (
        <div className="my-4">
          
          {/* Knot Animation */}
          <motion.svg
            width="100%"
            height="400"
            viewBox="0 0 400 400"
            onClick={toggleTie}
            style={{ cursor: 'pointer' }}
            aria-label="Interactive Knot Animation"
            role="img"
          >
            {/* Blue Rope */}
            <motion.path
              d={blueRopePath}
              stroke="#007bff" // Blue color
              strokeWidth="6"
              fill="none"
              variants={isTied ? tieVariants : untieVariants}
              initial="untied"
              animate={isTied ? 'tied' : 'untied'}
            />
  
            {/* Red Rope */}
            <motion.path
              d={redRopePath}
              stroke="#ff0000" // Red color
              strokeWidth="6"
              fill="none"
              variants={isTied ? tieVariants : untieVariants}
              initial="untied"
              animate={isTied ? 'tied' : 'untied'}
            />
  
            {/* Green Rope */}
            <motion.path
              d={greenRopePath}
              stroke="#28a745" // Green color
              strokeWidth="6"
              fill="none"
              variants={isTied ? tieVariants : untieVariants}
              initial="untied"
              animate={isTied ? 'tied' : 'untied'}
            />
  
            {/* Animated Horizontal Ropes */}
            {!isTied && (
              <>
                {/* Animated Blue Rope */}
                <motion.path
                  d="M10,160 H390" // Adjusted Y position for blue rope (closer to red)
                  stroke="#007bff"
                  strokeWidth="6"
                  fill="none"
                  initial={{ pathLength: 0 }} // Starts with no visible length
                  animate={{ pathLength: 1 }} // Animates to full length
                  transition={{
                    delay: 3, // Delay matches untie animation duration
                    duration: 2,
                    ease: 'easeInOut',
                  }}
                />
  
                {/* Animated Red Rope */}
                <motion.path
                  d="M10,200 H390" // Adjusted Y position for red rope (closer to green)
                  stroke="#ff0000" // Red color
                  strokeWidth="6"
                  fill="none"
                  initial={{ pathLength: 0 }} // Starts with no visible length
                  animate={{ pathLength: 1 }} // Animates to full length
                  transition={{
                    delay: 3, // Delay matches untie animation duration
                    duration: 2,
                    ease: 'easeInOut',
                  }}
                />
  
                {/* Animated Green Rope */}
                <motion.path
                  d="M10,240 H390" // Adjusted Y position for green rope
                  stroke="#28a745" // Green color
                  strokeWidth="6"
                  fill="none"
                  initial={{ pathLength: 0 }} // Starts with no visible length
                  animate={{ pathLength: 1 }} // Animates to full length
                  transition={{
                    delay: 3, // Delay matches untie animation duration
                    duration: 2,
                    ease: 'easeInOut',
                  }}
                />
              </>
            )}
          </motion.svg>
  
          {/* Display Selected Date */}
          <div className="mt-2">
            <p>{selectedDate ? selectedDate.toDateString() : 'No date selected'}</p>
          </div>

          {/* Color Key */}
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '4em',
              marginTop: '2em',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '1em',
              }}
            >
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  backgroundColor: '#007bff', // Blue
                  marginRight: '0.5em',
                }}
              ></div>
              <span style={{ fontSize: '0.9em', fontWeight: 'bold' }}>Past Experiences</span>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '1em',
              }}
            >
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  backgroundColor: '#ff0000', // Red
                  marginRight: '0.5em',
                }}
              ></div>
              <span style={{ fontSize: '0.9em', fontWeight: 'bold' }}>Thought Form</span>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  borderRadius: '50%',
                  backgroundColor: '#28a745', // Green
                  marginRight: '0.5em',
                }}
              ></div>
              <span style={{ fontSize: '0.9em', fontWeight: 'bold' }}>Pure Sensation (Feeling)</span>
            </div>
          </div>
  
  
          <button
            style={{
              fontFamily: 'Arial, sans-serif',
              fontSize: '18px',
              padding: '15px 30px',
              marginTop: 'auto',
              marginBottom: '10px',
              borderRadius: '8px',
              width: '90%',
            }}
            className="btn btn-primary mt-3"
            onClick={toggleTie}
            aria-label={isTied ? 'Untie Knot' : 'Finish'}
          >
            {isTied ? 'unlearn emotional knot' : 'replay'}
          </button>
          
          <button
          style={{
            fontFamily: 'Arial, sans-serif',
            fontSize: '18px',
            padding: '15px 30px',
            marginBottom: '90px',
            borderRadius: '8px',
            width: '90%',
          }}
          className="btn btn-primary mt-3"
          onClick={() => (window.location.href = 'https://toys4spirit.com/energy-of-everything')}
          aria-label="Back to Energy of Everything"
        >
          Finish
        </button>
          
        </div>
      )}
    </div>
  );
  
};

export default EnergyofEverything;
