import { initializeApp } from 'firebase/app';
import "firebase/firestore";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/storage'; 
import { getStorage } from 'firebase/storage';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCYL4jdQ4luRJuwBSHVqfBuJV4J7Pt_UcU",
    authDomain: "energyvoo.firebaseapp.com",
    projectId: "energyvoo",
    storageBucket: "energyvoo.appspot.com",
    messagingSenderId: "459716366735",
    appId: "1:459716366735:web:c167d67922ab6990b67bfa",
    measurementId: "G-HWNDGWFXZH"
  };

firebase.initializeApp(firebaseConfig)


const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app)
export const authentication = getAuth(app);
export const storage = getStorage(app);
