import React, { useState, useEffect } from 'react';
// import './App.css';
// import Modal from 'react-modal';
// import Navbar from './Navbar'; // Import the Navbar component
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Form, Col, Row, Alert} from 'react-bootstrap';
// import Form from 'react-bootstrap/Form';
// import backgroundImage from './bg2.jpg';
// import axios from 'axios';
import moment from 'moment';
// import { useHistory } from "react-router-dom";
// import mixpanel from 'mixpanel-browser';

// / create an instance of the mixpanel client
// mixpanel.init("24661fba09998e465e6f435c094ba5a2", {track_pageview: true});

var person
var past
var future
var future2
var future3
var future4
var future5
var future6
var future7
var current
var advice
var newgdates



const AgreementPage = ({ onNext, pastImportance, futureImportance, futureImportance2, futureImportance3, futureImportance4, futureImportance5, futureImportance6, futureImportance7, livingThing}) => {
  const [dates, setDates] = useState([]);
  const [gdates, setGdates] = useState([]);
  const [newgdate, setNewGdates] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImportance, setCurrentImportance] = useState(0);
  const [newgdates, setNewgdates] = useState('');
  const [start, setStart] = useState(0);
  // const [now, setNow] = useState(0);

  // const [livingThing, setlivingThing] = useState('');
  const milliRange = 3000; // 1 second
  // newgdates = newgdate
  // console.log('newgdate: ',newgdates)
  // console.log('past importance:', pastImportance);
  // console.log('future importance:', futureImportance);
  // console.log('future importance2:', futureImportance2);
  // console.log('future importance3:', futureImportance3);
  // console.log('future importance4:', futureImportance4);
  // console.log('future importance5:', futureImportance5);


  useEffect(() => {
    // Generate an array of dates
    const currentDate = moment();
    const oneDay = moment(currentDate).add(1, 'day');
    const oneWeek = moment(currentDate).add(1, 'week');
    const oneMonth = moment(currentDate).add(1, 'month');
    const oneYear = moment(currentDate).add(1, 'year');
    const tenYears = moment(currentDate).add(10, 'years');
    const fiftyYears = moment(currentDate).add(50, 'years');
    const twohundredYears = moment(currentDate).add(200, 'years');

    const today = currentDate._d
    const oneDayLater = oneDay._d
    const oneWeekLater = oneWeek._d
    const oneMonthLater = oneMonth._d
    const oneYearLater = oneYear._d
    const tenYearsLater = tenYears._d
    const fiftyYearsLater = fiftyYears._d
    const twohundredYearsLater = twohundredYears._d

    // console.log(oneDayLater)
    // console.log(oneWeekLater)
    // console.log(oneMonthLater)
    // console.log(oneYearLater)
    // console.log("twohundredYearsLater: ", twohundredYearsLater)

    // const datesArray = [
    //   oneDayLater.format('DD/MMM/YYYY'),
    //   oneWeekLater.format('DD/MMM/YYYY'),
    //   oneMonthLater.format('DD/MMM/YYYY'),
    //   oneYearLater.format('DD/MMM/YYYY'),
    //   tenYearsLater.format('DD/MMM/YYYY'),
    // ];

    const datesArray = [
      today,
      oneDayLater,
      oneWeekLater,
      oneMonthLater,
      oneYearLater,
      tenYearsLater,
      fiftyYearsLater,
      twohundredYearsLater
    ];

    console.log(datesArray)

    setDates(datesArray);
  }, []);

useEffect(() => {
  const ranges = [0, pastImportance, futureImportance, futureImportance2, futureImportance3, futureImportance4, futureImportance5, futureImportance6, futureImportance7];
  let currentRangeIndex = 0;
  const animationDuration = 5000; // Set the duration of the animation in milliseconds (5 seconds)
  const framesPerSecond = 50;
  const totalFrames = (animationDuration / 1000) * framesPerSecond;
  const frameIncrement = (ranges[currentRangeIndex + 1] - ranges[currentRangeIndex]) / totalFrames;
  let frameCount = 0;
  // console.log('datescheck: ',dates)

  
  var intervalId = setInterval(() => {
    setCurrentImportance((prevImportance) => {
      var progress = frameCount / totalFrames;
      var easedProgress = 1 - Math.pow(1 - progress, 1); // Ease-out

      var newImportance =
        ranges[currentRangeIndex] < ranges[currentRangeIndex + 1]
          ? Math.min(prevImportance + frameIncrement + easedProgress * (ranges[currentRangeIndex + 1] - prevImportance), ranges[currentRangeIndex + 1])
          : Math.max(prevImportance + frameIncrement + easedProgress * (ranges[currentRangeIndex + 1] - prevImportance), ranges[currentRangeIndex + 1]);

      frameCount += 1;

      if (frameCount >= totalFrames || newImportance === ranges[currentRangeIndex + 1]) {
        frameCount = 0;
        currentRangeIndex += 1;
        // setGdates(dates[currentRangeIndex])
        // Reset to the initial range if all ranges have been covered
        if (currentRangeIndex === ranges.length - 1) {
          currentRangeIndex = 0;
          newImportance = pastImportance
        }
        // Update newgdates with the formatted value of the current date
        // if (dates[currentRangeIndex].length > 0) {
          const options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            weekday: 'short',
          };
          const formattedDate = dates[currentRangeIndex].toLocaleDateString('en-GB', options);
          const formattedString = formattedDate.replace(' ', ' ').replace(' ', ' ');
          console.log('currentRangeIndex: ', currentRangeIndex)
          console.log('dateformated: ', formattedString)
          console.log('new_importance: ', newImportance)
          setNewgdates(formattedString);
        // }

      }

      return newImportance;
    });
  }, 1000 / framesPerSecond);

  // Cleanup the interval on component unmount
  return () => clearInterval(intervalId);
}, [dates]);

// useEffect(() => {
//   // Update label when currentImportance changes
//   setNewgdates(newgdate);
// }, []);


return (
  <div
    className="page"
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '100vh',
      padding: '20px',
      boxSizing: 'border-box',
    }}
  >
    <h3
      style={{
        fontFamily: 'Arial, sans-serif',
        fontSize: '24px',
        fontWeight: 'normal',
        marginTop: '30px',
        marginBottom: '20px',
        textAlign: 'center',
        color: '#333',
        width: '80%',
      }}
    >
      Here goes the life-span of that memory:
    </h3>

    <input
      type="range"
      min="0"
      max="5"
      value={currentImportance}
      readOnly
      style={{
        width: '80%',
        marginTop: '20px',
        appearance: 'none',
        height: '35px',
        borderRadius: '5px',
        background: '#CED4DA',
        outline: 'none',
        backgroundImage: `linear-gradient(to right, ${
          currentImportance >= 3 ? '#ff4d4d' : '#4CAF50'
        } 0%, ${
          currentImportance >= 3 ? '#ff4d4d' : '#4CAF50'
        } ${(currentImportance / 5) * 100}%, #CED4DA ${(currentImportance / 5) * 100}%, #CED4DA 100%)`,
      }}
    />

    <div style={{ textAlign: 'center', marginTop: '10px' }}>
      <label
        style={{
          fontFamily: 'Arial, sans-serif',
          fontSize: '18px',
          color: '#333',
        }}
      >
        {newgdates}
      </label>
    </div>

    <div style={{ textAlign: 'center', marginTop: '10px' }}>
      <span
        style={{
          fontFamily: 'Arial, sans-serif',
          fontSize: '16px',
          color: '#555',
        }}
      >
        {livingThing}
      </span>
    </div>

    <Button
      variant="primary"
      onClick={() => onNext(currentImportance)}
      style={{
        fontFamily: 'Arial, sans-serif',
        fontSize: '18px',
        padding: '15px 30px',
        marginTop: 'auto',
        marginBottom: '90px',
        borderRadius: '8px',
        width: '90%',
      }}
    >
      Finish
    </Button>
  </div>
);
};

const FeedbackPage = ({onNext, pastImportance, futureImportance, futureImportance2, futureImportance3, futureImportance4, futureImportance5, futureImportance6, futureImportance7, livingThing})=>{

  const [now, setNow] = useState(0);

return (
    <div className="page"
     style={{ 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between', 
        minHeight: '100vh', 
        paddingBottom: 20,
        alignItems: 'center', // Centers content horizontally
        padding: '20px', // Adds padding around the content
        // backgroundColor: '#f5f5f5', // Background color (can be changed)
        boxSizing: 'border-box', // Ensures padding doesn't affect width
    }}>
      <h3
        style={{
          fontFamily: 'Young Serif',
          fontSize: '30px',
          fontWeight: 800,
          margin: 5,
          marginTop: 30,
          width: '80%',
          textAlign: 'center',
        }}
      >
        Send yourself a positive future message:
      </h3>

      {/* TODO: place text input here */}

      {/* Spacing for the second range input */}
      <div
        style={{
          marginTop: 'auto',
          textAlign: 'center',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: 20, 
        }}
      >
        
        <input
          style={{
            borderRadius: 30,
            height: 30,
            width: '80%',
            appearance: 'none',
            background: `linear-gradient(to right, ${
            now >= 3 ? 'red' : 'green'
          } 0%, ${
            now >= 3 ? 'red' : 'green'
          } ${now * 20}%, #CED4DA ${now * 20}%, #CED4DA 100%)`,
        }}
        
          variant="outline-dark"
          type="range"
          min="0"
          max="5"
          value={now}
          onChange={(e) => setNow(Number(e.target.value))}
        />
        <Form.Text className="text-muted">
          How worried do you feel now?
        </Form.Text>
      </div>

      <Button
        style={{
          fontFamily: 'Lexend Zetta',
          fontSize: '20px',
          fontWeight: 80,
          marginBottom: 70,
          marginTop: 10,
          borderRadius: 30,
          height: 60,
          width: '90%',
        }}
        variant="dark"
        onClick={() => onNext()}
      >
        F i n i s h
      </Button>
    </div>
  );
}


const RelationshipPage = ({ onNext }) => {
  // const relationships = [
  //   'Myself',
  //   'Friend',
  //   'Girlfriend',
  //   'Boyfriend',
  //   'Husband',
  //   'Wife',
  //   'Ex',
  //   'Father',
  //   'Mother',
  //   'Sibling',
  //   'Cousin',
  //   'Child',
  //   'Work Colleague',
  //   'Work Boss',
  //   'Classmate',
  //   'Neighbor',
  //   'Random Person',
  //   'Online Person',
  // ];

  const relationships = [
    'The Altar',
    'Work',
    'Home',
    'Friends',
    'School',
    "Dad's Side",
    "Mom's side",
    'TV',
    'Online',
    'Facebook',
    'X',
    'Instagram',
    'Tiktok',
    'Snapchat',
    'YouTube',
    'Discord',
    "WhatsApp",
    'Linkedin',
    'Hinge',
    'Tinder',
    'Telegram',
    'The Bus',
    'Church',
    'Temple',
    'Mosque',
    'The Bed',
    'The Womb',
    'The Streets',
    'The Past',
    'The Gutters',
    "The Cinema",
    "Family",
    'The Party',
    "The Club",
    'Dating App',
  ];

  const [personName, setPersonName] = useState(null);
  const [selectedRelationship, setSelectedRelationship] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  console.log('personName: ', personName)
  if (personName == null){
    person = 'the situation'
  } else{
    person = 'what ' + personName + ' did'
  }
  

  const handleNext = () => {
    // if (personName == '') {
    //   alert("You need to enter the name of a person who recently upset you.");
    //   return;
    // }
    // mixpanel.track('startButton', {
    //   name: personName,
    //   relationship: selectedRelationship,
    //   time: new Date().toLocaleString()
    // })
    onNext({
      name: personName,
      relationship: selectedRelationship,
    });
  };


  return (
    <div
      className="page"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 35,
        // minHeight: '200vh',
        padding: '20px',
        backgroundColor: '#ffffff',
      }}
    >
      <h3
        style={{
          fontFamily: 'Arial, sans-serif',
          fontSize: '24px',
          fontWeight: 'bold',
          marginBottom: '20px',
          textAlign: 'center',
          color: '#333',
        }}
      >
        What do you feel ashamed about?
      </h3>
  
      <div
        style={{
          width: '100%',
          maxWidth: '400px',
          marginBottom: '30px',
        }}
      >
        <Form.Control
          as="textarea"
          rows={1}
          style={{
            width: '100%',
            padding: '10px 15px',
            fontSize: '16px',
            borderRadius: '8px',
            border: '1px solid #ccc',
            boxSizing: 'border-box',
            resize: 'none',
            marginTop: 15,
          }}
          type="text"
          value={personName}
          onChange={(e) => setPersonName(e.target.value)}
          placeholder="Enter a name..."
        />
      </div>
  
      <Button
        variant="primary"
        style={{
          fontFamily: 'Arial, sans-serif',
          fontSize: '18px',
          padding: '12px 30px',
          borderRadius: '8px',
          width: '100%',
          maxWidth: '200px',
        }}
        onClick={handleNext}
      >
        Start
      </Button>
    </div>
  );
};

const ImportancePage = ({ question, personName, pastImportance, onNext, livingThing }) => {
  const [importance, setImportance] = useState(0);

  useEffect(() => {
  
    if (current === 1){
      past = importance
      // mixpanel.track('past', {
      //   name: personName,
      //   past: past,
      //   time: new Date().toLocaleString()
      // })
    }else if(current === 2  && future === undefined){
      future = importance
      // setImportance(past);
    }else if(current === 2 && future !== undefined){
      future = importance
      // setImportance(past);
    }else if(current === 3 && future2 === undefined){
      future2 = importance
      // setImportance(future);
    }else if(current === 3 && future2 !== undefined){
      future2 = importance
      // setImportance(past);
    }else if(current === 4 && future3 === undefined){
      future3 = importance
      // setImportance(future2);
    }else if(current === 4 && future3 !== undefined){
      future3 = importance
      // setImportance(past);
    }else if(current === 5 && future4 === undefined){
      future4 = importance
      // setImportance(future3);
    }else if(current === 5 && future4 !== undefined){
      future4 = importance
      // setImportance(past);
    }else if(current === 6 && future5 === undefined){
      future5 = importance
      // setImportance(future4);
    }else if(current === 6 && future5 !== undefined){
      future5 = importance
      // setImportance(past);
    }else if(current === 7 && future6 === undefined){
      future6 = importance
      // setImportance(future5);
    }else if(current === 7 && future6 !== undefined){
      future6 = importance
      // setImportance(past);
    }else if(current === 8 && future7 === undefined){
      future7 = importance
      // setImportance(future6);
    }else if(current === 8 && future7 !== undefined){
      future7 = importance
      // setImportance(past);
      // mixpanel.track('deathbed', {
      //   name: personName,
      //   importance: [past,future,future2,future3,future4,future5,future6,future7],
      //   time: new Date().toLocaleString()
      // })
    }
      console.log('importance:', importance);
      console.log('past importance:', past);
      console.log('future importance:', future);
      console.log('future importance2:', future2);
      console.log('future importance3:', future3);
      console.log('future importance4:', future4);
      console.log('future importance5:', future5);
      console.log('future importance6:', future6);
      console.log('future importance6:', future7);
      console.log('current_page:', current);
    // Set the initial value only if importance is not already set
    // if (importance === 0) {
    //   setImportance(pastImportance);
    // }
  }, [importance]);

  return (
    <div
      className="page"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '20px',
        boxSizing: 'border-box',
      }}
    >
      <h3
        style={{
          fontFamily: 'Arial, sans-serif',
          fontSize: '24px',
          fontWeight: 'normal',
          marginTop: '30px',
          marginBottom: '20px',
          textAlign: 'center',
          color: '#333',
          width: '80%',
        }}
      >
        {question}
      </h3>
  
      <input
        type="range"
        min="0"
        max="5"
        value={importance}
        onChange={(e) => setImportance(Number(e.target.value))}
        style={{
          width: '80%',
          marginTop: '20px',
          appearance: 'none',
          height: '35px',
          borderRadius: '5px',
          background: '#CED4DA', // Default track color
          outline: 'none',
          backgroundImage: `linear-gradient(to right, ${
            importance >= 3 ? '#ff4d4d' : '#4CAF50'
          } 0%, ${
            importance >= 3 ? '#ff4d4d' : '#4CAF50'
          } ${(importance / 5) * 100}%, #CED4DA ${(importance / 5) * 100}%, #CED4DA 100%)`,
        }}
      />
  
      <Button
        variant="primary"
        onClick={() => onNext(importance)}
        style={{
          fontFamily: 'Arial, sans-serif',
          fontSize: '18px',
          padding: '15px 30px',
          marginTop: 'auto',
          marginBottom: '90px',
          borderRadius: '8px',
          width: '90%',
        }}
      >
        Next
      </Button>
    </div>
  );
};

const ModalContent = ({ onBack }) => {
  // const history = useHistory();

  const quotes = [
    '"The most important relationship in your life is the relationship you have with yourself." - Diane von Furstenberg',
    "\"You can't give to others what you don't have for yourself. Make self-care a priority.\" - Oprah Winfrey",
    '"Your relationship with yourself sets the tone for every other relationship you have." - Robert Holden',
    '"Self-care is never a selfish act; it is simply good stewardship of the only gift I have, the gift I was put on this earth to offer to others." - Parker Palmer',
    '"Love yourself first, and everything else falls into line." - Lucille Ball',
    '"You are your best thing." - Toni Morrison',
    "\"Your time and energy are precious. You get to choose how you use it. Don't waste your essence on anything that doesn't serve you.\" - Maria Forleo",
    '"When you adopt the viewpoint that there is nothing that exists that is not part of you, that there is no one who exists who is not part of you, that any judgment you make is self-judgment, you will wisely extend to yourself an unconditional love that will be the light of the world." - Harry Palmer',
    '"The better you feel about yourself, the less you feel the need to show off." - Robert Hand',
    '"Self-love is the source of all our other loves." - Pierre Corneille'
]

  const getRandomQuote = () => {
    const randomIndex = Math.floor(Math.random() * quotes.length);
    return quotes[randomIndex];
  };

  const shareOnWhatsApp = () => {
    // mixpanel.track('sharedWhatsapp', {
    //   quote: randomQuote,
    //   time: new Date().toLocaleString()
    // })
    const shareUrl = "https://worryKiller.com"; // Replace with your actual website URL
    const message = "Marriage life is wonderful but can come with some worrying. Get perspective on your worries with "; // Customize your message

    const whatsappLink = `whatsapp://send?text=${encodeURIComponent(`${message} ${shareUrl}`)}`;
    window.location.href = whatsappLink;
  };

  const [randomQuote, setRandomQuote] = useState("");
  advice = randomQuote
  useEffect(() => {
    // if (currentPage === 5) {
      const quote = getRandomQuote();
      setRandomQuote(quote);
    // }
    console.log(randomQuote)
  }, []);

  return (
    <div
      className="page"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        padding: '20px',
        boxSizing: 'border-box',
      }}
    >
      <div>
        <h3
          style={{
            fontFamily: 'Arial, sans-serif',
            fontSize: '24px',
            fontWeight: 'normal',
            marginTop: '30px',
            marginBottom: '20px',
            textAlign: 'center',
            color: '#333',
            // width: '80%',
          }}
        >
          {advice}
        </h3>
      </div>
      <Button
        variant="primary"
        onClick={shareOnWhatsApp}
        style={{
          fontFamily: 'Arial, sans-serif',
          fontSize: '18px',
          padding: '15px 30px',
          marginTop: 'auto',
          marginBottom: '90px',
          borderRadius: '8px',
          width: '90%',
        }}
      >
        WhatsApp
      </Button>
    </div>
  );
};

const PastChanges = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [relationship, setRelationship] = useState('');
  const [personName, setPersonName] = useState('');
  const [pastImportance, setPastImportance] = useState(0);
  const [futureImportance, setFutureImportance] = useState(0);
  const [livingThing, setLivingThing] = useState(''); // Add livingThing state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [agreements, setAgreements] = useState({
    lowerExpectations: false,
    lowerExpectationsB: false,
    lowerExpectationsC: false,
    // Add other agreements as needed
  });

 


  const handleNext = (value) => {
    if (currentPage === 1) {
      current = currentPage
      setPersonName(value.name);
      setRelationship(value.relationship);
    } else if (currentPage === 2) {
      current = currentPage
      setPastImportance(value);
    } else if (currentPage === 3) {
      current = currentPage
      setFutureImportance(value);
      // handleModalOpen(); // Open the modal after setting the futureImportance
    }else if (currentPage === 4) {
      current = currentPage
      setFutureImportance(value);
      // handleModalOpen(); // Open the modal after setting the futureImportance
    }else if (currentPage === 5) {
      current = currentPage
      setFutureImportance(value);
      // handleModalOpen(); // Open the modal after setting the futureImportance
    }else if (currentPage === 6) {
      current = currentPage
      setFutureImportance(value);
      // handleModalOpen(); // Open the modal after setting the futureImportance
    }else if (currentPage === 7) {
      current = currentPage
      setFutureImportance(value);
      // handleModalOpen(); // Open the modal after setting the futureImportance
    }else if (currentPage === 8) {
      current = currentPage
      setFutureImportance(value);
      // handleModalOpen(); // Open the modal after setting the futureImportance
    }else if (currentPage === 9) {
      current = currentPage
      setFutureImportance(value);
      // handleModalOpen(); // Open the modal after setting the futureImportance
    }else if (currentPage === 10) {
      current = currentPage
      setAgreements(value);
      handleModalOpen(); // Open the modal after setting the futureImportance
    }else if (currentPage === 11) {
      current = currentPage
      // setAgreements(value);
      // handleModalClose(); // Open the modal after setting the futureImportance
      // TODO: Suppose compare vexations
    }else if (currentPage === 12) {
      current = currentPage
      // setAgreements(value);
      handleModalClose(); // Open the modal after setting the futureImportance
    }
    setCurrentPage(currentPage + 1);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };
  
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleBackToPage1 = () => {
    setCurrentPage(1);
    setIsModalOpen(false);
  };

  return (
    <div className="App" style={{ backgroundImage: `url(${'backgroundImage'})`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
    {/* <div className="App" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}> */}
      {/* Navbar */}
      {/* <Navbar
        currentPage={currentPage}
        personName={personName}
        appName="MyButtons"
      /> */}
      {currentPage === 1 && <RelationshipPage onNext={handleNext} />}
      {currentPage === 2 && (
        <ImportancePage
          question={`How many people will remember that in a year ? `}
          personName={personName}
          onNext={handleNext}
          setLivingThing={setLivingThing} // Pass setLivingThing to ImportancePage if needed

        />      
        )}
      {currentPage === 3 && (
        <ImportancePage
          question={`How many people will remember that in 2 years ?`}
          personName={personName}
          onNext={handleNext}
          livingThing={livingThing} // Pass livingThing to AgreementPage
          setLivingThing={setLivingThing} // Pass setLivingThing to AgreementPage
          // setLivingThing={'Mayfly'} // Pass setLivingThing to AgreementPage
        />
      )}
      {currentPage === 4 && (
        <ImportancePage
          question={`How many people will remember that in 5 years ?`}
          personName={personName}
          onNext={handleNext}
          livingThing={livingThing} // Pass livingThing to AgreementPage
          setLivingThing={setLivingThing} // Pass setLivingThing to AgreementPage
          // setLivingThing={'mosquitoes'} // Pass setLivingThing to AgreementPage
        />
      )}
      {currentPage === 5 && (
        <ImportancePage
          question={`How many people will remember that in 10 year ?`}
          personName={personName}
          onNext={handleNext}
          livingThing={livingThing} // Pass livingThing to AgreementPage
          setLivingThing={setLivingThing} // Pass setLivingThing to AgreementPage
          // setLivingThing={setLivingThing} // Pass setLivingThing to AgreementPage
        />
      )}{currentPage === 6 && (
        <ImportancePage
          question={`How many people will remember that in 50 years?`}
          personName={personName}
          onNext={handleNext}
          livingThing={livingThing} // Pass livingThing to AgreementPage
          setLivingThing={setLivingThing} // Pass setLivingThing to AgreementPage
          // setLivingThing={'Housefly'} // Pass setLivingThing to AgreementPage

        />
      )}{currentPage === 7 && (
        <ImportancePage
          question={`How many people will remember what happened in 100 years?`}
          personName={personName}
          onNext={handleNext}
          livingThing={livingThing} // Pass livingThing to AgreementPage
          setLivingThing={setLivingThing} // Pass setLivingThing to AgreementPage
          // setLivingThing={'Dog'} // Pass setLivingThing to AgreementPage

        />
      )}{currentPage === 8 && (
        <ImportancePage
          question={`How many people will remember what happened in 500 years?`}
          personName={personName}
          onNext={handleNext}
          livingThing={livingThing} // Pass livingThing to AgreementPage
          setLivingThing={setLivingThing} // Pass setLivingThing to AgreementPage
          // setLivingThing={'Elephant'} // Pass setLivingThing to AgreementPage

        />
      )}{currentPage === 9 && (
        <ImportancePage
          question={`How many people will remember what happened in 1000 years?`}
          personName={personName}
          onNext={handleNext}
          livingThing={livingThing} // Pass livingThing to AgreementPage
          setLivingThing={setLivingThing} // Pass setLivingThing to AgreementPage
          // setLivingThing={'Human'} // Pass setLivingThing to AgreementPage

        />
      )}
      {/* {currentPage === 4 && <AgreementPage onNext={handleNext} />} */}
      {currentPage === 10 && <AgreementPage onNext={handleNext} pastImportance={past} futureImportance={future} futureImportance2={future2} futureImportance3={future3} futureImportance4={future4} futureImportance5={future5} futureImportance6={future6} futureImportance7={future7}/>}
      {/* {currentPage === 11 && <FeedbackPage onNext={handleNext}/>}  */}
      {currentPage === 11 && (
        <div>
          {/* <h7>{displayedAdvice}</h7> */}
          <ModalContent onBack={handleBackToPage1} displayedAdvice={advice} />
        </div>
      )}
    </div>
  );
};


// const TimeHeals = () => {
//   return (
//     <div>
//       <h1>TimeHeals</h1>
//       <p>This is the TimeHeals app. More content coming soon!</p>
//     </div>
//   );
// };


export default PastChanges;