import React from "react";
import "./styles.css";

const Earth = () => {
  return (
    <div className="earth-container">
      {/* Render 33 properly concentric circles */}
      {[...Array(33)].map((_, i) => (
        <div
          key={i}
          className="circle"
          style={{
            borderWidth:3,
            borderColor: `rgba(0, 255, 0, ${0.05 + i * 0.06})`, // Dark green at the center, lighter outward
            height: `${300 - i * 9}px`, // Decrease size incrementally
            width: `${300 - i * 9}px`,
            top: `calc(50% - ${(300 - i * 9) / 2}px)`, // Center vertically
            left: `calc(50% - ${(300 - i * 9) / 2}px)`, // Center horizontally
          }}
        ></div>
      ))}
    </div>
  );
};

export default Earth;
