import React, { useState, useEffect  } from 'react';
import { Link } from 'react-router-dom';
import { Card, Form, Container, Row, Col, Button, Carousel, Modal } from 'react-bootstrap';
import { FaHeart, FaStar } from 'react-icons/fa'; // Icon for the star
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { getAuth } from "firebase/auth";

// let timeHealsScore

const HomePage = () => {
  const [favorites, setFavorites] = useState([]);
  const navigate = useNavigate();
  const [activeCategory, setActiveCategory] = useState(0); // Track the currently selected main card
  const [showModal, setShowModal] = useState(false);
  const [logicTests, setLogicTests] = useState([]); // Store logic test questions and options
  const [userAnswers, setUserAnswers] = useState({}); // Track user-selected answers
  const [score, setScore] = useState(0); // Track the user score
  const [userIdd, setUserIdd] = useState("");
  const [selectedAppPath, setSelectedAppPath] = useState(''); // Track the app path
  const [selectedAppTitle, setSelectedAppTitle] = useState(''); // Track the app path
  const [updatedAnswers, setUpdatedAnswers] = useState(''); // Track the app path

  // const [timeHealsScore, setTimeHealsScore] = useState('');

  const db = getFirestore(); // Firestore instance
  const auth = getAuth();

  const  [categories, setCategories] = useState([
    {
      name: "😌",
      apps: [
        { name: 'Time Heals', profileImage:"https://upload.wikimedia.org/wikipedia/commons/2/21/Sadhguru-Jaggi-Vasudev.jpg", title: "time_heals", path: '/time-heals', comingSoon: false, successRate:'85', intro: "It can't fight time (v1)", by: "Sadhguru" },
        // { name: 'We Can\'t Go There', path: '/we-cant-go-there', comingSoon: true },
        // { name: "Good Bitter", profileImage:"https://upload.wikimedia.org/wikipedia/commons/3/3f/Mooji_Wikipedia_Photo.jpg", title: "good_bitter", path: '/everything-is-perceived', comingSoon: true, successRate: 'Undone', intro: 'You will observe everything is coming towards you', by: "Mooji" },
          { name: "Good Bitter", profileImage:"https://static.wixstatic.com/media/af1176_0189f41c7bea459391a4f275dd9de276~mv2.jpg/v1/crop/x_0,y_55,w_2730,h_2731/fill/w_560,h_560,al_c,q_80,usm_0.66_1.00_0.01,enc_avif,quality_auto/IMG_6302_JPG.jpg", title: "good_bitter", path: '/everything-is-perceived', comingSoon: false, successRate: 'Undone', intro: 'Emerald Tablet', by: "Thoth" },
      ]
    },
    {
      name: "😎",
      apps: [
        { name: 'Some Perspective', profileImage:"https://upload.wikimedia.org/wikipedia/commons/2/21/Sadhguru-Jaggi-Vasudev.jpg", title: "some_perspective", path: '/force-of-everything', comingSoon: false, successRate: '51%', intro: "It can't fight time (v2)", by: "Sadhguru" },
        { name: 'Magic of Increments', profileImage:"https://upload.wikimedia.org/wikipedia/commons/5/5e/Tony_Robbins.jpg", title: "magic_of_increments", path: '/incremental-magic', comingSoon: false, successRate: '81%', intro: 'Give yourself the permission to be super human for 5 minutes', by: "Tony Robbins" },
        { name: 'Unlearn the Knot', profileImage:"https://upload.wikimedia.org/wikipedia/commons/3/3f/Paramahansa_Yogananda_Standard_Pose.jpg", title: "unlearn_the_knot", path: '/energy-of-everything', comingSoon: false, successRate: '95%', intro: 'Find when you first created this emotion', by: "Paramahansa Yogananda" },
        // { name: 'Energy Pools', path: '/energy-pools', comingSoon: true },
        // { name: "Good Bitter", profileImage:"https://upload.wikimedia.org/wikipedia/commons/3/3f/Mooji_Wikipedia_Photo.jpg", title: "good_bitter", path: '/everything-is-perceived', comingSoon: true, successRate: 'Undone', intro: 'You will observe everything is coming towards you', by: "Mooji" },
      ]
    },
    {
      name: '🥰',
      apps: [
        { name: 'Unlearn the Knot',  profileImage: "https://upload.wikimedia.org/wikipedia/commons/3/3f/Paramahansa_Yogananda_Standard_Pose.jpg", title: "unlearn_the_knot", path: '/energy-of-everything', comingSoon: false, successRate: '86%', intro: 'Find when you first created this emotion', by: "Paramahansa Yogananda" },
        { name: 'Some Perspective (shame)',  profileImage:"https://upload.wikimedia.org/wikipedia/commons/2/21/Sadhguru-Jaggi-Vasudev.jpg", title: "some_perspective", path: '/past-changes', comingSoon: false, successRate: '54%', intro: "It can't fight time  (v3)", by: "Sadhguru" },
        // { name: "Good Bitter",  profileImage:"https://upload.wikimedia.org/wikipedia/commons/3/3f/Mooji_Wikipedia_Photo.jpg", title: "good_bitter", path: '/everything-is-perceived', comingSoon: true, successRate: 'Undone', intro: 'You will observe everything is coming towards you', by: "Mooji" },
        // { name: "Ancestral Pool", profileImage:"https://hips.hearstapps.com/hmg-prod/images/gettyimages-3091504.jpg", title: "ancestral_pool", path: '/experiences-die', comingSoon: true, successRate: 'Undone', intro: 'Energies exists in their pure forms', by: "Albert Einstein"},
        { name: "Ancestral Pool v2", profileImage:"https://hips.hearstapps.com/hmg-prod/images/gettyimages-3091504.jpg", title: "ancestral_pool", path: '/experiences-die', comingSoon: false, successRate: 'Undone', intro: 'Energies exists in their pure forms', by: "Albert Einstein"},
        // { name: 'We Can\'t Go There', path: '/we-cant-go-there', comingSoon: true },
      ]
    },
    // {
    //   name: "💔 -> 🥰",
    //   apps: [
    //     // { name: "Subliminal", path: '/everything-is-perceived', comingSoon: true },
    //     { name: "Ancestral Pool", title: "ancestral_pool", path: '/experiences-die', comingSoon: true, successRate: 'Undone', },
    //   ]
    // }
  ]);


  const toggleFavorite = (appName) => {
    if (favorites.includes(appName)) {
      setFavorites(favorites.filter(fav => fav !== appName));
    } else {
      setFavorites([...favorites, appName]);
    }
  };

  const toGame = async (appPath) => {
     // Upload the score and selected options to Firestore
     const docRef = doc(db, 'transmutters', userIdd);
  
     const payload = {
       [selectedAppTitle]: {
         options: updatedAnswers, // Save the latest answers
         score: score, // Save the calculated score
       },
     };
   
     try {
       await setDoc(docRef, payload, { merge: true }); // Save data to Firestore
       console.log('Score and options uploaded successfully.');
     } catch (error) {
       console.error('Error uploading score and options:', error);
     }

    navigate(appPath); // Push the new page to the history stack
  };

  // Get the number of results in the current category
  const resultsCount = categories[activeCategory].apps.length;


  const handleCloseModal=()=>{
    setShowModal(false)
    setLogicTests([]);
    setUserAnswers({});
    setScore(0);
  }


  const logic_test = async (title, appPath) => {
    setSelectedAppTitle(title)
    setSelectedAppPath(appPath);
    const user = auth.currentUser;
    if (user) {
      const userid = user.uid;
      setUserIdd(userid)
      // console.log(userid)
      const docRef = doc(db, 'transmutters', userid);

  
      try {
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          const savedTest = data[title];
    
          if (savedTest) {
            setUserAnswers(savedTest.options || {}); // Populate previously selected options
            setScore(savedTest.score || 0); // Populate the previously calculated score
          }
        }
      } catch (error) {
        console.error('Error loading saved test data:', error);
      }
    
      // Fetch logic tests for the current test
      const logicTestRef = doc(db, 'transmutters', title);
      const logicTestSnap = await getDoc(logicTestRef);
    
      if (logicTestSnap.exists()) {
        const data = logicTestSnap.data();
        setLogicTests(data.logic_test || []); // Set logic_test array
        setShowModal(true); // Show the modal
      } else {
        console.error('No such document!');
      }

    } else {
      console.log("No user is signed in.");
    }
 
  };

  const handleOptionChange = (testIndex, selectedOptionIndex) => {
    setUserAnswers((prev) => {
      const updatedAnswers = {
        ...prev,
        [testIndex]: selectedOptionIndex, // Update the selected option for the current test
      };
      
      calculateScore(selectedAppTitle, updatedAnswers); // Trigger score calculation with updated answers
      setUpdatedAnswers(updatedAnswers)
      return updatedAnswers;
    });
  };

  const calculateScore = async (appTitle, currentAnswers) => {
    let totalScore = 0;
    const numQuestions = logicTests.length; // Get the number of questions
  
    logicTests.forEach((test, index) => {
      const selectedOptionIndex = currentAnswers[index]; // Use passed-in answers
      if (selectedOptionIndex !== undefined) {
        totalScore += parseInt(test.answer[selectedOptionIndex] || 0, 10); // Add score based on the answer array
      }
    });
  
    // Calculate the average score if there are multiple questions
    const averageScore = numQuestions > 1 ? totalScore / numQuestions : totalScore;
  
    setScore(averageScore); // Update the score in state
  
  };


  // useEffect(() => {

  //   const fetchScores = async () => {
  //   const user = auth.currentUser;
  //   if (user) {
  //     const userid = user.uid;
  //     // const userId = 'exampleUserId'; // Replace with logic to get current user ID
  //     const docRef = doc(db, 'transmutters', userid);
  
  //     try {
  //       const docSnap = await getDoc(docRef);
  //       if (docSnap.exists()) {
  //         const userScores = docSnap.data();
  //         // console.log(userScores[0])
  //         console.log(userScores.time_heals.score)
  //         // setTimeHealsScore(userScores.time_heals.score)
  //         timeHealsScore = userScores.time_heals.score
  
  //         // setCategories((prevCategories) =>
  //         //   prevCategories.map((category) => ({
  //         //     ...category,
  //         //     apps: category.apps.map((app) => ({
  //         //       ...app,
  //         //       successRate: userScores[app.title]?.score
  //         //         ? `${userScores[app.title].score}%`
  //         //         : app.successRate,
  //         //     })),
  //         //   }))
  //         // );
  //       }
  //     } catch (error) {
  //       console.error('Error fetching user scores:', error);
  //     }
  //   }else{
  //     console.log('not signed in')
  //   }
      
  //   };
  
  //   // Add a delay before calling fetchScores
  //   const timeout = setTimeout(() => {
  //     fetchScores();
  //   }, 1000); // 1-second delay

  //   // Cleanup timeout to avoid memory leaks
  //   return () => clearTimeout(timeout);
  // }, []);
  

  return (
    <Container className="py-3">
      {/* Header Section */}
      <div className="text-center mb-4">
        <h2
          style={{
            fontFamily: "'Roboto', sans-serif",
            color: '#6c757d',
            fontWeight: '700',
          }}
        >
          Transmute NEs into ?
        </h2>
      </div>
  
      {/* Category Carousel */}
      <Carousel
        activeIndex={activeCategory}
        onSelect={(selectedIndex) => setActiveCategory(selectedIndex)}
        interval={null}
        prevIcon={
          <span
            aria-hidden="true"
            className="carousel-control-prev-icon"
            style={{ filter: 'invert(0)' }}
          />
        }
        nextIcon={
          <span
            aria-hidden="true"
            className="carousel-control-next-icon"
            style={{ filter: 'invert(0)' }}
          />
        }
      >
        {categories.map((category, index) => (
          <Carousel.Item key={index}>
            <div
              className="text-center p-5"
              style={{
                backgroundColor: '#ff853d',
                borderRadius: '8px',
              }}
            >
              <h2
                style={{
                  fontFamily: "'Roboto', sans-serif",
                  color: 'white',
                  fontWeight: '500',
                }}
              >
                {category.name}
              </h2>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
  
      {/* Results Found Section */}
      <div className="text-center mt-4 mb-3">
        <h6
          style={{
            fontFamily: "'Roboto', sans-serif",
            color: '#6c757d',
          }}
        >
          <b>{resultsCount - 1} transmutters found:</b>
        </h6>
      </div>
  
      {/* Apps Grid */}
      <Row
        style={{
          maxHeight: '550px',
          overflowY: 'auto',
          paddingRight: '0px',
        }}
        className="mt-4"
      >
        {categories[activeCategory].apps.map((app, index) => (
          <Col key={index} xs={12} md={6} lg={4} 
            className="mb-4">
            <Card
              className="mb-4 shadow-sm"
              onClick={() => logic_test(app.title, app.path)}
              style={{
                position: 'relative',
                backgroundColor: '#ffffff',
                borderRadius: '8px',
                height: '100%', // Ensure all cards are the same height
                display: 'flex', // Enables flexbox layout for consistent content distribution
                flexDirection: 'column', // Stack content vertically
              }}
            >
              {/* Overlay for Coming Soon */}
              {app.comingSoon && (
                <div
                  style={{
                    borderRadius: '8px',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '1.0em',
                    color: '#6c757d',
                    fontWeight: 'bold',
                    marginTop: 10,
                  }}
                >
                  Coming Soon
                </div>
              )}

              <Card.Body
                style={{
                  fontFamily: "'Roboto', sans-serif",
                  position: 'relative',
                  zIndex: 2,
                  flex: 1, // Ensures the body stretches to fill available height
                  display: 'flex', // Enables flexbox layout inside the card body
                  flexDirection: 'column', // Stack title, subtitle, and button vertically
                  justifyContent: 'space-between', // Space out content evenly
                }}
              >
                <Card.Title
                  style={{
                    fontSize: '1.25em',
                    color: '#333',
                    marginBottom: '0.5em',
                  }}
                >
                  {app.intro}
                </Card.Title>
                <Card.Subtitle
                  style={{
                    fontSize: '0.8em',
                    color: '#6c757d',
                    marginBottom: '1em',
                    display: 'flex', // Use flexbox for alignment
                    justifyContent: 'center', // Center the entire subtitle content
                    alignItems: 'center', // Align text and image vertically
                  }}
                >
                  Inspired by&nbsp; {/* Add a non-breaking space after "by" */}
                  <b>{app.by}</b>
                  <div
                    style={{
                      marginLeft: '10px', // Space between "by" and the profile picture
                      marginRight: '10px', // Space between the profile picture and the name
                      width: '30px',
                      height: '30px',
                      borderRadius: '50%',
                      overflow: 'hidden',
                      border: '1px solid #ddd', // Light border for distinction
                      backgroundColor: '#f0f0f0', // Fallback for missing image
                    }}
                  >
                    <img
                      src={app.profileImage} // Profile picture URL
                      alt={`${app.by}'s profile`}
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover', // Ensure the image fills the circle
                      }}
                    />
                  </div>
                </Card.Subtitle>

                <Button
                  variant="primary"
                  onClick={() => logic_test(app.title, app.path)}
                  style={{
                    width: '100%',
                    height: '45px',
                    fontSize: '1em',
                    marginTop: 15,
                  }}
                  disabled={app.comingSoon}
                >
                  <img src="./play.png" width="30" style={{  }} />
                  {/* <img src="./esports3.png" width="35" style={{ marginRight: 5 }} /> */}
                </Button>
              </Card.Body>
            </Card>
          </Col>

))}

      </Row>
  
      {/* Modal for Logic Test */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Magick-Logic Score</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {logicTests.map((test, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <p style={{ fontWeight: 'bold' }}>{test.question}</p>
              {test.options.map((option, optionIndex) => (
                <Form.Check
                  key={optionIndex}
                  type="radio"
                  id={`test-${index}-option-${optionIndex}`}
                  name={`test-${index}`}
                  label={option}
                  onChange={() => handleOptionChange(index, optionIndex)}
                  checked={userAnswers[index] === optionIndex}
                />
              ))}
            </div>
          ))}
  
          {score > 0 && (
            <div
              style={{
                textAlign: 'center',
                marginTop: '30px',
                fontWeight: 'bold',
                fontSize: '1.2em',
              }}
            >
              You'll get at least {score}% value from this transmutter
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {/* Go Back */}
            <img src="./back.png" width="25" style={{ }} />
          </Button>
          <Button
            variant="primary"
            onClick={() => toGame(selectedAppPath)}
            style={{ fontWeight: 'bold' }}
          >
            {/* Play */}
            <img src="./play.png" width="25" style={{ }} />
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
  
};

export default HomePage;
