import React, { useState } from "react";
import Earth from "./Earth";
import UserPosture from "./UserPosture";
import "./styles.css";

const ExperiencesDie = () => {
  const [posture, setPosture] = useState("standing"); // Default posture
  const [energy, setEnergy] = useState(50); // Default energy level at 50%

  return (
    <div
      className="app"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh", // Full viewport height
        textAlign: "center",
      }}
    >
      <h1>Mother Earth's Gravity Visualization</h1>

      <div className="controls" style={{ marginBottom: "20px" }}>
        <label htmlFor="posture">Select Posture: </label>
        <select
          id="posture"
          value={posture}
          onChange={(e) => setPosture(e.target.value)}
          style={{
            padding: "5px",
            fontSize: "16px",
            marginRight: "10px",
          }}
        >
          <option value="standing">Standing</option>
          <option value="sitting">Sitting</option>
          <option value="laying">Laying</option>
        </select>

        <label htmlFor="energy">Set Energy: </label>
        <input
          id="energy"
          type="number"
          value={energy}
          onChange={(e) => setEnergy(Math.max(0, Math.min(100, e.target.value)))} // Clamp value between 0 and 100
          style={{
            padding: "5px",
            fontSize: "16px",
            width: "60px",
            marginLeft: "10px",
          }}
        />%
      </div>

      <div
        className="visualization"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vw", // Dynamic scaling
          width: "80vw",
          maxHeight: "500px", // Cap the size for larger screens
          maxWidth: "500px",
          position: "relative",
        }}
      >
        <Earth />
        <UserPosture posture={posture} energy={energy} />
      </div>
    </div>
  );
};

export default ExperiencesDie;
