import React, {useState, useEffect, useRef} from "react";
import {Form,  Modal, FloatingLabel, Button, InputGroup, Alert, Card, Breadcrumb, Container, Row, Col} from 'react-bootstrap'
// import 'firebase/compat/firestore';
import firebase from 'firebase/compat/app';
import { collection, addDoc, query, whre, getDocs, getFirestore, count } from 'firebase/firestore';
import { firestore, authentication, storage } from '../firebase';
import 'firebase/compat/firestore';
import { doc, updateDoc, arrayUnion, setDoc, getDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
// import { storage } from './firebase';
import axios from "axios";

// const firestore = getFirestore();
const auth = getAuth();

let data_low;
let data_up = null
let giant;
let energy;
let ene_comp;
let ene_sup;
let fullenergy
let fullName
let freqTop
let subUrl
let audioUrl
let source
// let biquadFilter
// let gainNode
let new_data
let audioContext
let bColor
let audioBlobUrl
let userid
let subIndexG


const IncrementalMagic = () => {
  const [data,setData]=useState('Happy');
  const [cards, setCards] = useState([]);
  const db = firebase.firestore();
  const [showIfEmpty, setShowIfEmpty] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [conversation, setConversation] = useState(null);
  const [fullname, setFullname] = useState('null');
  // const [fullenergy, setFullenergy] = useState('null');
  const [pastins, setPastins] = useState('null');
  const [comp, setComp] = useState('null');
  const [sup, setSup] = useState('null');
  const [audioUrls, setAudioUrls] = useState([]);
  const [audio1Urls, setAudio1Urls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [replayCount, setReplayCount] = useState(0);
  const [selectedAudioUrl, setSelectedAudioUrl] = useState(null); // State to track the selected audio URL
  const audioRef = useRef(null);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [myDesire, setDesire] = useState(null);
  const [countdown, setCountdown] = useState(60); // Countdown state
  const [frequency, setFrequency] = useState(0); // Frequency state
  const [fred, setFred] = useState(0); // Frequency state
  const [resistance, setResistance] = useState(0); // Resistance state
  const [selectedCard, setSelectedCard] = useState(null); // State to store selected card details
  const [isIncreasing, setIsIncreasing] = useState(false);
  const [showNewContent, setShowNewContent] = useState(false);
  const [isHuman, setIsHuman] = useState(false);
  const [article, setArticle] = useState('');
  const [showTraits, setShowTraits] = useState(true);
  const [showGenerated, setShowGenerated] = useState(false);
  const [showSpells, setShowSpells] = useState(true);
  const [searchText, setSearchText] = useState("");
  const [inspirationText, setInspirationText] = useState("");
  const [filteredHints, setFilteredHints] = useState([]);
  const [hints, setHints] = useState([]);
  const [showSignInButton, setShowSignInButton] = useState(false); // Add state for sign-in modal
  const [user, setUser] = useState(auth.currentUser);
  const [sheyRaw, setSheyRaw] = useState("Raw Energy to Human Energy");
  const [firstName, setFirstName] = useState('');
  const [countdownTime, setCountdownTime] = useState(0); // 3:33 minutes in milliseconds
  const [isCountingDown, setIsCountingDown] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [dots, setDots] = useState(3);
  const [index, setIndex] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [corn, setCorn] = useState('Humans');
  const [selectedOption, setSelectedOption] = useState("");
  const [otherOption, setOtherOption] = useState("");
  const [isRestart, setIsRestart] = useState(false);
  const [selectedSmallCard, setSelectedSmallCard] = useState('Water');
  const [selectedSmallCard6, setSelectedSmallCard6] = useState('Masculine');
  const [selectedSmallCard9, setSelectedSmallCard9] = useState('Baby');
  const [settings, setSettings] = useState(false);
  const [explanation, setExplanation] = useState('');
  const [selectedPower, setPowerOption] = useState('');
  // const [freqTop, setFreqTop] = useState('');
  const [range_explanation, setRangeExplanation] = useState('');
  const [subfreq, setSubfreq] = useState(555); // Default to 17,500 Hz
  const [selectedBackground, setSelectedBackground] = useState(0);
  const [isButtonPressed, setIsButtonPressed] = useState(false);
  const [isButtonReleased, setIsButtonReleased] = useState(false);
  const [audibility, setAudibility] = useState('audible');
  const [numofmp3, setNumofmp3] = useState(0);
  const [tostart, setToStart] = useState(0);
  const [notifications, setNotifications] = useState(0);
  const [unplayed, setUnplayed] = useState([]);
  const handleShowSettings = () => setSettings(true);
  const [rangeValue, setRangeValue] = useState(0);

  const [inputText, setInputText] = useState(''); // State to hold input text


  const explanations = {
    Remember_how: "Remember how you are " + comp + ' than ' + article  + fullName,
    Remember_you: "Remember you are " + comp + ' than ' + article  + fullName,
    Imagine: "Imagine that you are " + comp + ' than ' + article  + fullName,
    Acknowledge: "Acknowledge that you are  " + comp + ' than ' + article  + fullName,
    Because: "Because you are " + comp + ' than ' + article  + fullName,
    Sooner: "Sooner or Later you will be " + comp + ' than ' + article  + fullName,
    I_find: "I find you to be " + comp + ' than ' + article  + fullName,
    Be_Like_If: "What Would It Be Like If you are " + comp + ' than ' + article  + fullName,
    Realize: "Realize that you are " + comp + ' than ' + article  + fullName,
    Suppose: "Suppose you are " + comp + ' than ' + article  + fullName,
    Like_when: "What is it like When you are "+ comp + ' than ' + article  + fullName, 
    Notice: "Notice that you are " + comp + ' than ' + article  + fullName,
    I_allow: "Allow yourself to be " + comp + ' than ' + article  + fullName,
  };

  const ranges = [
    3,
    6,
    9,
  ];

  

  const explanationsmorethanone = {
    Remember_how: "Remember how you are " + frequency + " times " + comp + ' than ' + article  + fullName,
    Remember_you: "Remember you are " + frequency + " times " + comp + ' than ' + article  + fullName,
    Imagine: "Imagine that you are " + frequency + " times " + comp + ' than ' + article  + fullName,
    Acknowledge: "Acknowledge that you are  " + frequency + " times " + comp + ' than ' + article  + fullName,
    Because: "Because you are " + frequency + " times " + comp + ' than ' + article  + fullName,
    Sooner: "Sooner or Later you will be " + frequency + " times " + comp + ' than ' + article  + fullName,
    I_find: "I find you to be " + frequency + " times " + comp + ' than ' + article  + fullName,
    Be_Like_If: "What Would It Be Like If you are " + frequency + " times " + comp + ' than ' + article  + fullName,
    Realize: "Realize that you are " + frequency + " times " + comp + ' than ' + article  + fullName,
    Suppose: "Suppose you are " + frequency + " times " + comp + ' than ' + article  + fullName,
    Like_when: "What is it like When you are "+ frequency + " times " + comp + ' than ' + article  + fullName, 
    Notice: "Notice that you are " + frequency + " times " + comp + ' than ' + article  + fullName,
    I_allow: "Allow yourself to be " + frequency + " times " + comp + ' than ' + article  + fullName,
  };

  const searchGreat = (e) => {
   
    if (e) {
    e.preventDefault();
    }

    if (new_data == ""){
        alert('Kindly write a search word')
        // setData('Happy')
        // mixpanel.track('searchGreatWithoutData', {
        //   data: 'null'
        // })
        return;
      }

      // mixpanel.track('searchGreat', {
      //   data: new_data
      // })
        
        data_low = new_data.toLowerCase();
        data_up = new_data.charAt(0).toUpperCase() + new_data.slice(1);

        // console.log(new_data)
        console.log(data_up)
        // console.log(data_low)

        if(data_up == null){
          setShowIfEmpty(false)
        }else{
          setShowIfEmpty(true)
        }
        // var data_low_rep = data_low.replace(' ', ',')
        const data_lowArray = data_low.split(' ');
        data_lowArray.push(data_low)
        // Query for 'name' field
        const queryByName = db.collection("greats")
        .where('name', 'array-contains-any', [data_up]);

        // Query for 'energy' field
        const queryByEnergy = db.collection("greats")
        .where('energy', 'array-contains-any', [data_up]);

        // Perform both queries concurrently using Promise.all()
        Promise.all([queryByName.get(), queryByEnergy.get()])
        .then(([nameSnapshot, energySnapshot]) => {
          // Combine the results from both queries
          const nameResult = nameSnapshot.docs.map(doc => ({
            docid: doc.id,
            ...doc.data()
          }));
          const energyResult = energySnapshot.docs.map(doc => ({
            docid: doc.id,
            ...doc.data()
          }));

          console.log(energyResult)

          // Merge the results and remove duplicates
          const mergedResult = [...nameResult, ...energyResult];
          const uniqueResult = mergedResult.filter((item, index, self) =>
            index === self.findIndex(t => (
              t.docid === item.docid && t.name === item.name
            ))
          );

          // Set the cards state with the merged and unique results
          setCards(uniqueResult);
          setShowTraits(false);

          console.log(uniqueResult)

          // Check if the result is empty
          if (uniqueResult.length === 0) {
            setShowIfEmpty(false);
            // handleShowUpload()
            alert(`Sorry, we haven't got this Energy yet. Try a closer Energy.`);
            setShowTraits(true);
          }
        })
        .catch((error) => {
          // console.error('Error searching for energy:', error);
        });

   }
   const lowercaseFirstLetter = (string) => {
    return string.charAt(0).toLowerCase() + string.slice(1);
  };

  const handleCardClick = async (ene, card_docid, cardname, card) => {
    // mixpanel.track('cardClicked', {
    //   card: cardname,
    //   energy: ene
    // })

    setFrequency(0)
    setFred(0)
    setResistance(0)
    setAudioUrls([]);
    // setCountdownTime(71000 * freqTop)
    setSelectedCard(card); // Set selected card details
    // console.log(ene)
    // console.log(audioUrls)
    // console.log(cardname)
    //Remove period at the end of each name part if it exists
    const cleanedNames = cardname.map(name => name.replace(/\.$/, ''));
    fullName = cleanedNames.join(' ');
    // console.log(cleanedNames)
    // fullName = cleanedNames
    // console.log(fullName)
    giant = fullName
    energy = ene
    var fullNamey = fullName.replace(/\./g, '');
    getArticle(giant)
    convertToComparative(ene)
    convertToSuperlative(ene)
    // setFullname(giant)
    fullenergy = ene
    // setShowModal(true);
    handleShowSettings(true)
    setPastins(null)
  
    const user = auth.currentUser;
    

    if (user) {
      const userid = user.uid
      console.log(userid)
      // const userDocRef = doc(firestore, 'inspire', userid);
      // mixpanel.track('handleCardClickUserSigned', {
      //   message: "card clicked while signed in"
      // })
      
      // try {
      //   // console.log("fullenergy: ", fullenergy)
      //   const fullenergyy = lowercaseFirstLetter(fullenergy)
      //   // console.log("fullenergyy: ", fullenergyy)
      //   // console.log("fullname: ", fullName)
      //   const energygreat = `${fullenergyy}${fullNamey}`;
      //   // console.log(energygreat)
      //   // Get the document
      //   const userDocRef = doc(firestore, 'inspire', energygreat);
      //   const docSnapshot = await getDoc(userDocRef);
  
      //   if (docSnapshot.exists()) {
      //     // Retrieve the 'energygreat' field
      //     const data = docSnapshot.data();
      //     // console.log(data)
      //     const dynamicFieldValue = Object.values(data).flatMap(userComments => 
      //       Object.values(userComments)
      //     );
      //     // console.log("data: ", data)
      //     // console.log("Energygreat array:", dynamicFieldValue);

      //     if (dynamicFieldValue) {
      //       // console.log("Energygreat array:", dynamicFieldValue);
      //       setPastins(dynamicFieldValue)
      //     } else {
      //       // console.log(dynamicFieldValue)
      //       // console.log("No " + energygreat + " field found in the document.");
      //     }
      //   } else {
      //     // console.log("No document found for the user.");
      //   }
       
      // } catch (error) {
      //   // console.error("Error fetching the document: ", error);
      // }
    } else {
      // console.log("No user is signed in");
    }
  };


  const searchGreatHint=(text)=>{
      new_data = text
      energy = text
      setData(text)
      setSearchText(text)
      
    searchGreat()
   
  }

  const irregularAdjectivesComparative = {
    'good': 'better',
    'bad': 'worse',
    'far': 'farther',
    'little': 'less',
    'much': 'more',
    'many': 'more',
    'strong': 'stronger',
    'sweet':'sweeter',
    'delicious':'more delicious',
    'masculine': 'more masculine',
    'charitable': 'more charitable',
    'old': 'older' // 'elder' is another comparative for 'old' in the sense of seniority within a family.
  };

  function convertToComparative(adjective) {
    const length = adjective.length;

    if (irregularAdjectivesComparative[adjective]) {
      ene_comp = irregularAdjectivesComparative[adjective];
    }
    
    
    // Handle adjectives ending in 'e'
    if (adjective.endsWith('e')) {
      ene_comp = adjective + 'r';
    }

    // Handle adjectives ending in 'e'
    if (adjective.endsWith('d')) {
      ene_comp = 'more ' + adjective;
    }

     // Handle adjectives ending in 'e'
     if (adjective.endsWith('l')) {
      ene_comp = 'more ' + adjective;
    }

    // Handle adjectives ending in 'e'
    if (adjective.endsWith('s')) {
      ene_comp = 'more ' + adjective;
    }
  
    // Handle adjectives ending in 'y'
    if (adjective.endsWith('y') && !isVowel(adjective[length - 2])) {
      ene_comp = adjective.slice(0, -1) + 'ier';
    }
    
    // Handle short adjectives (typically one syllable)
    if (length <= 4 || (length === 5 && isVowel(adjective[0]) && !isVowel(adjective[1]))) {
      ene_comp = adjective + 'er';
    }

    if (length > 6 ) {
      ene_comp = 'more ' + adjective;
    }

    // Handle long adjectives (typically two or more syllables)
    // ene_comp = 'more ' + adjective;
    setComp(ene_comp)
  }

  const irregularAdjectives = {
    'good': 'best',
    'bad': 'worst',
    'far': 'farthest',
    'little': 'least',
    'much': 'most',
    'many': 'most',
    'strong': 'strongest',
    'old': 'oldest',
    'delicious':'most delicious',
    'masculine': 'most masculine',
    'charitable': 'most charitable',
    'old': 'oldest',
    'sweet':'sweetest' // In the sense of age, 'eldest' is another superlative for 'old' in the sense of seniority within a family.
  };
  
  function isVowel(char) {
    return ['a', 'e', 'i', 'o', 'u'].includes(char.toLowerCase());
  }
  
  function convertToSuperlative(adjective) {
    const length = adjective.length;

    if (irregularAdjectives[adjective]) {
     ene_sup = irregularAdjectives[adjective];
    }
    
    
    // Handle adjectives ending in 'e'
    if (adjective.endsWith('e')) {
      ene_sup = adjective + 'st';
    }

    // Handle adjectives ending in 'e'
    if (adjective.endsWith('l')) {
      ene_sup = 'most ' + adjective;
    }

    // Handle adjectives ending in 'e'
    if (adjective.endsWith('d')) {
      ene_sup = 'most ' + adjective;
    }

    // Handle adjectives ending in 'e'
    if (adjective.endsWith('s')) {
      ene_sup = 'most ' + adjective;
    }
  
    // Handle adjectives ending in 'y'
    if (adjective.endsWith('y') && !isVowel(adjective[length - 2])) {
      ene_sup = adjective.slice(0, -1) + 'iest';
    }
    
    // Handle short adjectives (typically one syllable)
    if (length <= 4 || (length === 5 && isVowel(adjective[0]) && !isVowel(adjective[1]))) {
      ene_sup = adjective + 'est';
    }

    if (length > 6 ) {
      ene_sup = 'most' + adjective;
    }
    
    // Handle long adjectives (typically two or more syllables)
    // ene_sup = 'most ' + adjective;
    setSup(ene_sup)
  }
  
  const getArticle = (word) => {
    // List of uncountable nouns that don't use 'a' or 'an'
  const uncountableNouns = ['water', 'sand', 'rice', 'music', 'food', 'fire', 'wind', 'night'];
    // Check if the word starts with a vowel sound
    const vowels = ['a', 'e', 'i', 'o', 'u'];
    const firstLetter = word[0].toLowerCase();

    if(isHuman){
      setArticle(' ')
      return;
    }

    const uncountableThe = ['sea', 'river', 'wind', 'sky'];
    if (uncountableThe.includes(String(word).toLowerCase())) {
      setArticle('the')
      return;
    }


    // Check if the word is uncountable
  if (uncountableNouns.includes(String(word).toLowerCase())) {
    setArticle(' ')
    return;
  }
  
    if (vowels.includes(firstLetter)) {
      setArticle('an')
    } else {
      setArticle('a');
    }
  };

  // Filter cards based on the isHuman state
  const filteredCards = isHuman
    ? cards.filter(card => !card.s)
    : cards.filter(card => card.s);

   const borderColors = ['red', 'blue', 'green', 'orange', 'purple', 'teal'];

    const handleCloseSettings = () => {
      setSettings(false)
      // setShowModal(true)
      setPowerOption('')
  }

  const handlePowerChange = (e) => {
    const value = e.target.value;
    setPowerOption(value);
    setExplanation(explanations[value] || '');
  };

  const handleRangeChange = (e) => {
    setRangeValue(parseInt(e.target.value));
    // console.log(rangeValue)
    // console.log(ranges[rangeValue])
    freqTop = ranges[rangeValue]
  };

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const playInfo = () => {
    // mixpanel.track('PlayIntro', {
    //   // user: userEmail,
    //   // energy: ene
    // })
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch(error => {
          // console.error("Error playing the audio:", error);
        });
      }
      setIsPlaying(!isPlaying);
    }
  }

  const handleStart = () => {
    // mixpanel.track('beginBooster', {
    //   // user: userEmail,
    //   energy: energy,
    //   sub: explanation,
    //   numberofRep: rangeValue
    // })
  
    if(selectedPower == '') {
      alert('Please choose a subliminal')
      return;
    }
    setSettings(false)
    setShowModal(true)
    // setPowerOption('')
    
  }

  
  const handleCloseModal = () => {
    // mixpanel.track('modalClosed', {
    // })
    // console.log(audioUrls[0])
    // setSelectedAudioUrl(null); // Reset the selected audio URL to null when the modal closes
    // console.log(audioUrls[0])
    setShowNewContent(false)
    setIsCountingDown(false)
    setInspirationText("")
    setFrequency(0)
    setFred(0)
    setResistance(0)
    setIsIncreasing(false)
    setShowModal(false);
    setSelectedOption('')
    setOtherOption('')
    setSelectedSmallCard('Water')
    setRangeValue(0)
    setNotifications(0)
  };

  const handleBack = () => {
    // mixpanel.track('backButton', {
    //   message: "backButton clicked"
    // })
    
    setShowNewContent(false);
  };

  const handleStartStop = () => {
    // mixpanel.track('handleStartStop', {
    //   frequency: frequency
    // })

    const user = auth.currentUser;

    if (user) {
      userid = user.uid;

  

      if (isRestart) {
        setFrequency(0); // Reset frequency to 1
        setCountdownTime(freqTop); // Reset countdown time
        setIsRestart(false); // Reset restart state
        setIsCountingDown(true); // Start countdown
        setIsIncreasing(true); // Start increasing frequency
      } else {
      setIsCountingDown(!isCountingDown);
         if (frequency==1){
          setAudioUrls([]);
        // convertTextToAudio("Follow this imagination exercise. It may seem repetitive or impossible, but this is intentional. . . Picture your brain radiating energy and whenever you hear a number, the energy radiating from your brain increases by that number.");
        // convertTextToAudio("Follow this imagination exercise. It may seem repetitive or impossible, but this is intentional. . . Picture your heart energy expanding from the center of your chest outward outside your body and whenever you hear a number, your heart energy increases by that number.");  
        // convertTextToAudio("This exercise is based on Quantum Jumping. It may seem repetitive, but that's intentional. . . Whenever you hear a question, notice the subtle change in your energy. .");  
        // You are ' + comp + ' than ' + article  + fullname + '. How ?'
        // convertTextToAudio('Visualize an energy ' + comp + ' than ' + article  + fullname + '.');
        // convertTextToAudio("This exercise is based on connects you to your parallel lifes. Notice the subtle change in your energies, Whenever you hear the prompt. .");  
        convertTextToAudio("The Social Energy Contract is an ancient unspoken agreement where we are forced to release our emotions or energies, according to the time and type of outside stimulus. For example, being praised by a random stranger,  or being told off by our partner. In this exercise, we will release positive energies without waiting for outside permission or stimulus.")
        // convertTextToAudio('Visualize a cup of water charged with the ' + {energy} + ' energy of ' + article  + fullname + '.');
        setAudioUrls([]);
      }
      setIsIncreasing(!isIncreasing);
    }

  }else{
    setShowSignInButton(true); // Show the sign-in modal if user is not signed in
    return;
  }
  };

  useEffect(() => {
    if (frequency === 1) {
      setFred(1);
      setAudioUrls([]);
      // console.log(frequency);
      setAudioUrls([]);
      if (selectedPower) {
        convertTextToAudio(explanations[selectedPower]);
      }else{
        // console.log('selectedPower: ', selectedPower)
      }
    } else if (frequency > 1 && frequency <= ranges[rangeValue]) {
      setFred(frequency);
      setAudioUrls([]);
      // console.log(frequency);
      if (selectedPower) {
        convertTextToAudio(explanationsmorethanone[selectedPower]);
      }else{
        // console.log('selectedPower: ', selectedPower)
      }
    }
  }, [frequency, selectedPower, rangeValue]);

  const timerRef = useRef(null);


  useEffect(() => {
    if (isCountingDown & frequency > 0) {
      timerRef.current = setInterval(() => {
        setCountdownTime((prevTime) => {
          if (prevTime <= 1000) {
            clearInterval(timerRef.current);
            setIsRestart(true); // Set restart state when countdown reaches 0
            return 0;
          }
          return prevTime - 1000;
        });
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }
  
    return () => clearInterval(timerRef.current);
  }, [isCountingDown, frequency]);

  const convertTextToAudio = async (text) => {
    setIsLoading(true);
    console.log('started')

    try {
      setAudioUrls([]); // Reset audio URLs (if used for multiple audio playback)

      const response = await axios.post(
        'https://api.openai.com/v1/audio/speech',
        {
          model: 'tts-1-hd',
          input: text,
          voice: 'nova',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer sk-proj-qtV3m5XrhzKtwoTyBOKUT3BlbkFJWg5KX1pdLEzC4EBzn5HC',
          },
          responseType: 'arraybuffer', // Specify response type as binary data
        }
      );

      // Decode the audio data and create an audio buffer
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const audioBuffer = await audioContext.decodeAudioData(response.data);

      // Create an audio source and connect it to the destination (speakers)
      const source = audioContext.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContext.destination);

      // Play the audio
      source.start();

      console.log('Audio is playing');
    } catch (error) {
      console.error('Error converting text to audio:', error.message);
    } finally {
      setIsLoading(false); // Stop loading spinner
    }
  };

  // const handleStartStop = () => {
  //   if (!inputText.trim()) {
  //     alert('Please enter text to convert to audio!');
  //     return;
  //   }

  //   if (isRestart) {
  //     setFrequency(0); // Reset frequency to 0
  //     setIsRestart(false); // Reset restart state
  //     setIsCountingDown(true); // Start countdown
  //     setIsIncreasing(true); // Start increasing frequency
  //   } else {
  //     setIsCountingDown(!isCountingDown);

  //     if (frequency === 1) {
  //       setAudioUrls([]);
  //       setInputText()
  //       convertTextToAudio(inputText); // Play the input text
  //       setAudioUrls([]);
  //     }
  //     setIsIncreasing(!isIncreasing);
  //   }
  // };



  return (

    <Container style={{ fontFamily: "Risque", textAlign: 'left', marginTop: 5 }}>
            <Row>
              <Col sm>
                <Button onClick={() => searchGreatHint('fearless')} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', margin:2, color: "#4E4E4E", fontSize: 18 }}>Fearless</Button>
                <Button onClick={() =>searchGreatHint('attractive')} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', margin:2, color: "#4E4E4E", fontSize: 18  }}>Attractive</Button>
                <Button onClick={() =>searchGreatHint('creative')} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', margin:2, color: "#4E4E4E", fontSize: 18  }}>Creative</Button>
                <Button onClick={() =>searchGreatHint('rich')} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', margin:2, color: "#4E4E4E", fontSize: 18  }}>Rich</Button>
                <Button onClick={() =>searchGreatHint('sexy')} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', margin:2, color: "#4E4E4E", fontSize: 18  }}>Sexy</Button>
                <Button onClick={() =>searchGreatHint('strong')} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', margin:2, color: "#4E4E4E", fontSize: 18  }}>Strong</Button>
                <Button onClick={() =>searchGreatHint('funny')} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', margin:2, color: "#4E4E4E", fontSize: 18  }}>Funny</Button>
                <Button onClick={() => searchGreatHint('confident')} variant='light' style={{ backgroundColor: '#FFFFFF', borderColor: '#C7BEBE', margin:2, color: "#4E4E4E", fontSize: 18  }}>Confident</Button>
              </Col>
            </Row>

      <div style={{ textAlign: "center" }}>
        <div className="row">
          <div className="col text-center">
            {!showTraits && (
              <>
                <Container>
                  <Row style={{ textAlign: 'left', marginTop: 50, marginBottom: 30 }}>
                    <Col style={{ textAlign: "center", fontFamily: "Roboto Flex", fontStyle: "bold", fontWeight: 200, color: '#4E4E4E', fontSize:25 }}><b>{filteredCards.length} {data_up} Results: Click on Most-Relatable</b></Col>
                  </Row>
                </Container>
                <Row className="g-2" style={{ margin: '5px -2.5px', fontFamily: "Risque", }}>
                  {filteredCards.map((card, index) => (
                    <Col xs={12} sm={12} md={4} lg={3} key={index} className="d-flex justify-content-center" style={{ marginBottom: 10 }}>
                      <Card
                        className="h-100"
                        onClick={() => handleCardClick(data, card.docid, card.name, card)}
                        style={{ width: '18rem', 
                                margin: '10px', 
                                cursor: 'pointer',
                                borderRadius: '15px',
                                border: `0px solid ${borderColors[index % borderColors.length]}`, }}
                        >
                        <div style={{ width: '100%', height: '300px', overflow: 'hidden' }}>
                          <Card.Img variant="top" src={card.image} alt={card.name} style={{ width: '300px', height: '300px', objectFit: 'cover', borderTopRightRadius: '15px', borderTopLeftRadius: '15px' }} />
                        </div>
                        <div style={{ textAlign: 'center' }}></div>
                        <Card.Body>
                            <Card.Text>
                              <Card.Title>{card.name}</Card.Title>
                              <i style={{ fontFamily:"Roboto Flex", fontStyle:"thin", fontWeight:100 }}>{card.about}</i>
                            </Card.Text>
                          </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
                {isHuman? (
                  <Row>
                  <Col className="d-flex justify-content-center" style={{ marginTop: 20 }}>
                    <Button variant="link" onClick={() => setIsHuman(!isHuman)} 
                    style={{ 
                      textDecoration: 'none', color: '#4E4E4E'
                    }}>
                      <img src="./spa.png" width="50" /> 
                    </Button>
                  </Col>
                </Row>
                ):(
                  <Row>
                  <Col className="d-flex justify-content-center" style={{ marginTop: 20 }}>
                    <Button variant="link" onClick={() => setIsHuman(!isHuman)} 
                    style={{ 
                      textDecoration: 'none', color: '#4E4E4E', fontFamily: "Roboto Flex", fontStyle: "bold"
                    }}>
                      <img src="./spa2.png" width="50" /> 
                    </Button>
                  </Col>
                </Row>
                )
                }
              </>
            )}
            
          </div>
        </div>
      </div>   

      <Modal
        show={settings}
        onHide={handleCloseSettings}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group style={{ marginTop:10}} className="mb-3" controlId="exampleForm.ControlTextarea1">
          <div style={{ marginBottom:5, textAlign: 'left' }}>
            <Form.Text style={{ fontFamily: "Roboto Flex", fontSize: 12 }} className="text-muted">
              Affirmation?
            </Form.Text>
          </div>
            <Form.Select onChange={handlePowerChange} value={selectedPower} style={{ fontFamily: "Roboto Flex" }} aria-label="Default select example">
              <option value=""> Choose Affirmation? </option>
              <option value="Remember_how">Remember How (recommended)</option>
              <option value="Remember_you">Remember You Are (recommended)</option>
              <option value="Acknowledge">Acknowledge</option>
              <option value="Because">Because You are</option>
              <option value="Sooner">Sooner or Later</option>
              <option value="I_find">I Find You To Be</option>
              <option value="Be_Like_If">What Would It Be Like If</option>
              <option value="Like_when">What’s It Like When</option>
              <option value="Realize">Realize</option>
              <option value="Imagine">Imagine</option>
              <option value="Suppose">Suppose</option>
              <option value="Notice">I Notice You Are</option>
              <option value="I_allow">You allow Yourself to be (recommended)</option>
            </Form.Select>

            <div style={{marginTop: 20}}>
              {selectedPower &&(
              <div className="text-muted" style={{fontFamily: "Roboto Flex", fontSize: 16, textAlign: 'center'}}>
                    e.g: "{explanation}"
              </div>
              )}
            </div>
            
            <div style={{ marginBottom:5, marginTop:35, textAlign: 'left' }}>
              <Form.Text style={{ fontFamily: "Roboto Flex", fontSize: 12 }} className="text-muted">
                Number of Repetitions?
              </Form.Text>
            </div>
            <div style={{ textAlign: 'center', marginTop: '5px', height:'80px' }}>
                <input
                  style={{
                    borderRadius: 30,
                    marginTop: 10,
                    height: 10,
                    width: '80%',
                    appearance: 'none',
                    background: `linear-gradient(to right, black 0%, black ${rangeValue * 50.00}%, #CED4DA ${rangeValue * 50.00}%, #CED4DA 100%)`
                  }}
                  variant="outline-dark"
                  type="range"
                  min="0"
                  max="2"
                  value={rangeValue}
                  onChange={handleRangeChange}
                />
                <div style={{ color:'#808B96', marginTop: '5px', fontSize: '16px' }}>
                  {ranges[rangeValue]} Reps =  {formatTime(freqTop)} mins
                </div>

                {/* <div style={{marginTop: 10}}> */}
                {/* className="text-muted" style={{ fontFamily: "Roboto Flex", fontSize: 16, textAlign: 'center' }} */}
                  {/* {selectedPower &&( */}
                 
                    {/* <div className="text-muted" style={{ fontFamily: "Roboto Flex", fontSize: 16, textAlign: 'center' }}>
                      {formatTime(freqTop)} mins
                    </div> */}
                  {/* )} */}
                {/* </div> */}

                
              </div>
           
          </Form.Group> 

          

          <div style={{display: 'flex', justifyContent: 'center', marginBottom: 20,}}>
          <audio ref={audioRef} src="https://firebasestorage.googleapis.com/v0/b/troopsong-1a530.appspot.com/o/Alloy_tts-1-hd_1x_2024-07-14T07_04_16-954Z.mp3?alt=media&token=74b3be07-dc8f-4a47-9b8d-ac1df58c79f7" />
         

            <Button variant="light"
                    style={{ margin: 5, marginTop: 50, borderRadius: 100, width: 130, height: 55, color: '#636363' }}
                    onClick={handleStart}>
                      <>
                        {/* <img src="./play2.png" width="25" style={{ marginRight: 5 }} /> */}
                        <span> begin</span>
                      </>
                    
            </Button> 
          </div>
           
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSettings}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal> 



      {/* Modal component */}
      <Modal backdrop="static" show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          {/* <Modal.Title>  </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          
          {!showNewContent ? (
            <>
            {/* <div style={{marginBottom:3, textAlign: 'center', color: '#C4C4C4'}}>
              {info1}
            </div> */}
            <div style={{marginBottom:5, textAlign: 'center',  color: '#C4C4C4'}}>
            {/* make count down here from 3:33 to 0 */}
              <h3>{formatTime(countdownTime)} minutes </h3>
              {/* This is a 3:33 minutes session */}
            </div>

            {selectedCard && (
                <>
                  <div style={{ textAlign: 'center' }}>
                    <img 
                      src={selectedCard.image} 
                      alt={selectedCard.name} 
                      style={{ 
                        width: '250px', 
                        height: '250px', 
                        borderRadius: '15px', 
                        objectFit: 'cover' 
                      }} 
                    />
                  </div>
                </>
              )}

            

            <div style={{ textAlign: 'center', marginTop: 10}}>

            {/* <div style={{marginLeft:20, marginRight:20, marginBottom:10,  textAlign: 'center',}}>
            {info2}
            </div> */}

              <h3>
              {/* {countdown > 0 ? (
           
                <h3>
                <Countdown date={Date.now() + 60 * 1000}/>
                </h3>
              ) : (
               
              )} */}

              {/* </h3> */}

                <>
                <h5 style={{marginTop:10, textAlign: 'center', fontWeight:200}}> <b> {frequency} x {giant}</b> <a>
                {/* <img src={"./info.png"} width="20" alt="info" /> */}
                </a> </h5> 


               {/* <h7 style={{marginTop:30, textAlign: 'center'}}>  </h7> */}
                {/* <div class="col"> */}
                  <input
                    style={{
                      borderRadius: 30,
                      marginBottom: 15,
                      height: 20,
                      width: '80%',
                      appearance: 'none',
                      background: `linear-gradient(to right, black 0%, black ${frequency * (100/(ranges[rangeValue]))}%, #CED4DA ${frequency * (100/(ranges[rangeValue]))}%, #CED4DA 100%)`
                    }}
                    variant="outline-dark"
                    type="range"
                    min="0"
                    max={ranges[rangeValue]}
                    value={frequency}
                    onChange={(e) => setFrequency(Number(e.target.value))}
                    disabled
                  />

                  {/* place playing... here */}
                  {/* {isIncreasing ? (
                    <p style={{ marginTop: 0, color: 'green', fontSize:17, color: '#C4C4C4',  }}>playing{' .'.repeat(dots)}</p>
                    ) : (
                      <p style={{ marginTop: 2, color: 'red', fontSize:17, color: '#C4C4C4' }}>click start</p>
                    )
                  } */}

                  <div>
                  <div>
                  <div style={{fontSize: 20, marginBottom:0, textAlign: 'center',  color: '#808B96', marginLeft:30}}> {subfreq} Hz</div>

                          <input
                          style={{
                              borderRadius: 30,
                              marginBottom: 20,
                              height: 10,
                              width: '80%',
                              appearance: 'none',
                              background: `linear-gradient(to right, #808B96 0%, #808B96 ${subfreq * (100/(20000))}%, #CED4DA ${subfreq * (100/(20000))}%, #CED4DA 100%)`
                            }}
                            variant="outline-dark"
                            type="range"
                            min="20"
                            max="20000"
                            value={subfreq}
                            onChange={(e) => setSubfreq(Number(e.target.value))}
                            step="10"
                          />
                          <div>
                            {audioUrls.map((url, index) => (
                              <audio style={{ display: 'none' }} autoPlay key={index} controls src={url}></audio>
                            ))}
                          </div>

                      </div>
                 
                </div>

     
                <Button
                    variant="light"
                    style={{
                      margin: 5,
                      marginTop: 0,
                      marginBottom: 10,
                      borderRadius: 100,
                      width: 120,
                      height: 50,
                    }}
                    onClick={handleStartStop}
                  >
                    {isRestart ? (
                      <>
                        <img src="./restart.png" width="25" style={{ marginRight: 5 }} />
                        <span>restart</span>
                      </>
                    ) : isIncreasing ? (
                      <>
                        <span style={{ color: '#F39C12' }}>pause</span>
                      </>
                    ) : (
                      <>
                        <span>start</span>
                      </>
                    )}
                  </Button>
                  {isLoading && <p>Loading audio...</p>}

                  {/* <Button variant="light"
                    style={{ margin: 5, marginTop: 0, marginBottom:10, borderRadius: 100, width: 120, height: 50 }}
                    onClick={handleButtonClick}>
                        <>
                          <img src="./back.png" width="20" style={{ marginRight: 5 }} />
                        </>
                  </Button> */}

                  {/* <Button variant="light"
                    style={{ margin: 5, marginTop: 0, marginBottom:10, borderRadius: 100, width: 120, height: 50 }}
                    onClick={handleButtonClick}>
                        <>
                          <img src="./next.png" width="20" style={{ marginRight: 5 }} />
                          <span> Next</span> 
                        </>
                  </Button> */}
                {/* </div> */}

                {/* {rangeValue !== 0 && ( */}
                        <Button 
                          variant="light" 
                          style={{ margin: 5, marginTop: 0, marginBottom: 10, borderRadius: 100, width: 120, height: 50 }}
                          // onClick={handleNext}
                          onClick={handleCloseModal}
                        >
                          <>
                            {/* <img src="./done.png" width="25" style={{ marginRight: 5 }} /> */}
                           <span> finish </span> 
                          </>
                        </Button>
                      {/* )} */}
                      
                  
                </>

              </h3>
            </div>
            {/* {showSignInButton && (
                      <Button style={{width:'100%', marginTop:10}} variant="danger" onClick={signInWithGoogle}>
                        Sign in with Google
                      </Button>
                    )} */}
            </>
            ) : (
          <div style={{ textAlign: 'center' }}>
          <Form>
          {/* <Accordion defaultActiveKey="0" >
            <Accordion.Item eventKey="0">
              <Accordion.Header >
              <img src="./info.png" width="25" style={{ marginRight: 5 }} />
              <div style={{fontFamily:"Roboto Flex", color: '#636363',}}><i>Supercharge with Intention:</i> </div>
              </Accordion.Header>
              <Accordion.Body style={{backgroundColor:'#F4F4F4'}}>
                <div  style={{textAlign:"start", fontFamily:"Roboto Flex", textAlign:"start", fontSize: 14}}> 
                <img src="./info.png" width="25" style={{ marginRight: 5 }} />
               <i> Write one benefit someone else will gain from your transformation... </i> </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion> */}
          {/* <div>
            Benefitiary: {otherOption}
          </div> */}
         
          {/* <div>
            Benefitiary: {otherOption}
          </div>
          <div>
            Energy : {selectedSmallCard}
          </div>
          <div>
            Size: {descriptions[rangeValue]}
          </div>
           */}
                {/* <div style={{ marginTop: 0, overflowY: 'auto' }}>
                    {Array.isArray(pastins) && pastins.length > 0 ? (
                      [...pastins].reverse().map((insight, index) => (
                        <div key={index} className="card my-3">
                          <div className="card-body d-flex align-items-center">
                            <img
                              src={userPhoto}
                              style={{ width: 25, height: 25, borderRadius: '50%', marginRight: 10 }}
                            />
                            <div style={{ fontSize:15 }} className="card-text mb-0">{insight}</div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div></div>
                    )}
                    {showSignInButton && (
                      <Button variant="danger" onClick={signInWithGoogle}>
                        Sign in with Google
                      </Button>
                    )}
                </div> */}
           
          {/* <Form.Group style={{ marginTop:10}} className="mb-3" controlId="exampleForm.ControlTextarea1">
          <div style={{ marginBottom:5, textAlign: 'left' }}>
            <Form.Text style={{ fontFamily: "Roboto Flex", fontSize: 12 }} className="text-muted">
              Understanding Manifestation (Optional)
            </Form.Text>
          </div>
            <Form.Select onChange={handleSelectChange} value={selectedOption} style={{ fontFamily: "Roboto Flex" }} aria-label="Default select example">
              <option value="">Where is the boundary between You and Nature?</option>
              <option value="the_air">The Air</option>
              <option value="my_skin">My Skin</option>
              <option value="my_body">My Body</option>
              <option value="my_brain">My Brain</option>
              <option value="my_thoughts">My Thoughts</option>
              <option value="my_feelings">My Feelings</option>
              <option value="my_memory">My Memory</option>
              <option value="no_boundary">Everything is Nature</option>
            </Form.Select>
           
          </Form.Group>    */}
          
          </Form>    
          {/* uncomment to restore insight diary */}
              {/* <div>
                <Form.Group style={{ marginTop: 10, fontFamily: "Roboto Flex", display: "flex", alignItems: "center" }} className="mb-3" controlId="exampleForm.ControlTextarea1"> */}
                    {/* <Form.Control
                      onChange={handleInspirationChange}
                      value={inspirationText}
                      placeholder="post insights..."
                      as="textarea"
                      rows={3}
                      style={{ flexGrow: 1, marginRight: 5 }}
                    /> */}
                    {/* <Button
                      
                      style={{ margin: 0, borderRadius: 100 }}
                      variant="light"
                      onClick={saveInsight}
                    >
                      <img src="./note.png" width="30" />
                    </Button> */}
                  {/* </Form.Group> 
                </div> */}
                <div style={{ marginTop: 0, maxHeight: '250px', overflowY: 'auto' }}>
                    {Array.isArray(pastins) && pastins.length > 0 ? (
                      [...pastins].reverse().map((insight, index) => (
                        <div key={index} className="card my-3">
                          <div className="card-body d-flex align-items-center">
                            <img
                              src={insight.photo}
                              style={{ width: 35, height: 35, borderRadius: '50%', marginRight: 10 }}
                            />
                            <div style={{ fontSize:17 }} className="card-text mb-0">
                              {insight.text}
                            </div>
                            
                          </div>
                            <div style={{ margin: 5, color:'#808B96', fontFamily: "Roboto Flex", fontSize:12, textAlign:'right' }}>
                              <>{insight.time}</>
                              <> ({insight.date})</>
                            </div>
                        </div>
                      ))
                    ) : (
                      <div></div>
                    )}
                    {/* {showSignInButton && (
                      <Button variant="danger" onClick={signInWithGoogle}>
                        Sign in with Google
                      </Button>
                    )} */}
                </div>      
          
          <Button variant="light" 
            style={{ margin: 5, marginTop: 20, marginBottom: 10, borderRadius: 100, width: 115, height: 55 }}
            onClick={handleBack}>
                  <>
                  <img src="./back.png" width="20" style={{ marginRight: 5 }} />
                  <span style={{color: '#636363',}}> Back</span>
                </>
          </Button>
          {/* <Button variant="light" onClick={saveInsight} style={{ margin: 5, marginTop: 20, marginBottom: 10, borderRadius: 100, width: 115, height: 55 }}
            >
            < >
              <img src="./note.png" width="25" style={{ marginRight: 5 }} />
              <span style={{color: '#636363',}}> Post</span>
            </>
          </Button> */}
          {/* <div style={{ marginTop: 20 }}>
            Insights
          </div>
            <div style={{ marginTop: 15 }}>
                {Array.isArray(pastins) && pastins.length > 0 ? (
                  [...pastins].reverse().map((insight, index) => (
                    <div key={index} className="card my-3">
                      <div className="card-body d-flex align-items-center">
                        <img
                          src={userPhoto}
                          style={{ width: 35, height: 35, borderRadius: '50%', marginRight: 10 }}
                        />
                        <p className="card-text mb-0">{insight}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p> 
                 
                  </p>
                )}
                {showSignInButton && (
                  <Button variant="danger" onClick={signInWithGoogle}>
                    Sign in with Google
                  </Button>
                )}
            </div>  */}

          </div>
          
          
            )}

            
        </Modal.Body>
        {/* <Modal.Footer>
        
        <Link to="/play-audio">
          <Button variant="light" 
            style={{ margin: 5,  marginTop: 5, borderRadius: 100, width: 150, height: 60 }}
            onClick={handleCloseModal}>
            <img src="./upload2.png" width="25" />
          </Button>
        </Link>
        </Modal.Footer> */}
      </Modal>
    </Container>
   
  );
}



export default IncrementalMagic;